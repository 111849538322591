import templateApi from '@/api/core/templates';
import Vue from 'vue';

export const template = {
    state: {
        templatesForGroups: null, //Templates disponibles para grupos enteros
        templatesByModuleAndCourseStage: null,
    },
    mutations: {
        setTemplatesForGroups(state, templatesForGroups){
            state.templatesForGroups = templatesForGroups;
        },
        resetCenterInfo(state) {
            state.templatesForGroups = null;
        },
        setTemplatesByModuleAndCourseStage(state, templatesByModuleAndCourseStage){
            state.templatesByModuleAndCourseStage = templatesByModuleAndCourseStage;
        }
    },
    actions: {
        async getTemplatesForGroups(context, params){
            if(context.state.templatesForGroups && context.state.templatesForGroups !== "pending"){
                return context.state.templatesForGroups;
            }
            else{
                context.state.templatesForGroups = "pending";
                await templateApi.getTemplatesForGroups().then((response) => {
                    context.state.templatesForGroups = response.data.data;
                });
            }
        },
        async generateTemplateForGroup(context, params){
            await templateApi.generateTemplateForGroup(params).then((response) => {
                //Get the filename
                    const contentDisposition = response.headers['content-disposition'];
                    let regexResult = contentDisposition.match("filename[^;=\\n]*=((['\"]).*?\\2|[^;\\n]*)");

                    //Remove double quotes
                    let filename = regexResult[1].replace(/^"(.+(?="$))"$/, '$1');

                    //Create the blob
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
            });
        },
        async getTemplatesByModuleAndCourseStage(context, params){
            if(!params.moduleId || !params.courseStageId) return;
            if(!context.state.templatesByModuleAndCourseStage){
                context.state.templatesByModuleAndCourseStage = {};
            }
            if(!context.state.templatesByModuleAndCourseStage[params.moduleId]){
                Vue.set(context.state.templatesByModuleAndCourseStage, params.moduleId, {});
            }
            if(!context.state.templatesByModuleAndCourseStage[params.moduleId][params.courseStageId]){
                Vue.set(context.state.templatesByModuleAndCourseStage[params.moduleId], params.courseStageId, null);
            }

            if(context.state.templatesByModuleAndCourseStage[params.moduleId][params.courseStageId] 
                && context.state.templatesByModuleAndCourseStage[params.moduleId][params.courseStageId] !== "pending"){
                return context.state.templatesByModuleAndCourseStage[params.moduleId][params.courseStageId];
            }
            else{
                context.state.templatesByModuleAndCourseStage[params.moduleId][params.courseStageId] = "pending";
                await templateApi.getFunctionalityTemplatesForPrinting(params.moduleId, params.courseStageId).then(response => {
                    if(response && response.data.status == "ok"){
                        Vue.set(context.state.templatesByModuleAndCourseStage[params.moduleId], params.courseStageId,  response.data.data);
                    }
                });
            }
        }
    },
    getters: {
        getTemplatesForGroups: state => {
            return state.templatesForGroups;
        },
        getTemplatesByModuleAndCourseStage: state => {
            return state.templatesByModuleAndCourseStage;
        }
    }
}
