module.exports = {
    /* COLORS GENERALS */
    ieduca_template: {
        brand1: '#4670b5',
        brand1_less: '#9DB7DD', //brand1+87 -> sobre blanco (no es color exacto pero aproxima)
        brand1_hsl: '217',
        brand2: '#294c86',
        brand2_less: '#3c63a5', //brand2+e8 -> sobre blanco (no es color exacto pero aproxima)
        blackIeduca: '#152935',
        info: '#346ecd',
        active: '#2f5189',
        backColor: '#f4f7fb',
        font: 'Lato'
    },
    tokapp_template : {
        brand1: '#FFA525',
        brand1_less: '#ffc87a',
        brand1_hsl: '35',
        brand2: '#000000',
        brand2_less: '#252525',
        blackIeduca: '#000000',
        info: '#FFA525',
        active: '#FFA525',
        backColor: '#fffaf2',
        font: 'verdana'
    },
};