export default {
    'searchPerson': 'Nombre, Apellidos, Cédula, Usuario o Correo',
    "dni": "Cédula",
    "person.dni": "Cédula",
    "kc_invoice": {
        "cliente_cif_dni": "Cédula Cliente",
    },
    "payerNif": "RNC pagador",
    "nifRfc": "RNC Rfc",
    "nif": "RNC",
}