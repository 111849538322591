import Vue from 'vue';
import VueI18n from 'vue-i18n';

import CaMessages from './locales/ca/messages.ca.js';
import CaImportador from './locales/ca/importador.ca.js';
import CaPersona from './locales/ca/persona.ca.js';
import CaCountryTown from './locales/ca/countryTown.ca.js';
import CaPermissions from './locales/ca/roles.ca.js';
import CaErrors from './locales/ca/errors.ca.js';
import CaParam from './locales/ca/param.ca.js';
import CaClassParam from './locales/ca/class.ca.js';
import CaKeyAndCloud from './locales/ca/keycloud.ca.js';

import EsMessages from './locales/es/messages.es.js';
import EsImportador from './locales/es/importador.es.js';
import EsPersona from './locales/es/persona.es.js';
import EsCountryTown from './locales/es/countryTown.es.js';
import EsPermissions from './locales/es/roles.es.js';
import EsErrors from './locales/es/errors.es.js';
import EsParam from './locales/es/param.es.js';
import EsClassParam from './locales/es/class.es.js';
import EsKeyAndCloud from './locales/es/keycloud.es.js';

import EsCoMessages from './locales/es_co/messages.es_co.js';
import EsCoErrors from './locales/es_co/errors.es_co.js';

import EsVeMessages from './locales/es_ve/messages.es_ve.js';
import EsVeErrors from './locales/es_ve/errors.es_ve.js';

import EsMxMessages from './locales/es_mx/messages.es_mx.js';
import EsMxCountryTown from './locales/es_mx/countryTown.es_mx.js';
import EsMxErrors from './locales/es_mx/errors.es_mx.js';

import EsRdMessages from './locales/es_rd/messages.es_rd.js';

import TranslationFunctions from "../utils/TranslationFunctions";
import CaPrintTags from './locales/printTags.ca.js';
import EsPrintTags from './locales/printTags.es.js';

Vue.use(VueI18n);

let lang = localStorage.language;

if (typeof lang === 'undefined' || lang == "undefined" || lang == null || lang == "") {
    // Si no hi ha idioma definit, i no tenim encara usuari, agafem com a referència l'idioma del navegador.
    lang = TranslationFunctions.getNavigatorLocale();
}

Vue.config.i18nPluralizer = (string, count) => {
  const parts = string.split('|');
  if (count === 1) {
      return parts[0];
  }

  return parts[1];
};

const messages = {
    ca : { ...CaMessages, ...CaImportador, ...CaPersona, ...CaCountryTown, ...CaPermissions, ...CaErrors, ...CaParam, ...CaClassParam, ...CaPrintTags, ...CaKeyAndCloud },
    es : { ...EsMessages, ...EsImportador, ...EsPersona, ...EsCountryTown, ...EsPermissions, ...EsErrors, ...EsParam, ...EsClassParam, ...EsPrintTags, ...EsKeyAndCloud },
    es_co : { ...EsCoMessages, ...EsCoErrors},
    es_ve : { ...EsVeMessages, ...EsVeErrors},
    es_mx : { ...EsMxMessages, ...EsMxCountryTown, ...EsMxErrors},
    es_rd : { ...EsRdMessages},
};

export default new VueI18n({
    locale: lang,
    fallbackLocale: {
        'es_co': ['es'],
        'es_mx': ['es'],
        'es_ve': ['es'],
        'es_rd': ['es'],
        'default': ['es']
    },
    messages: messages,
    silentFallbackWarn: true /* Per evitar warnings de traduccions */,
    silentTranslationWarn: true /* Per evitar warnings de traduccions */
});

