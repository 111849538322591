import entityHelper from '@/api/entityHelper';
import axios from 'axios';
import StringFunctions from '@/utils/stringFunctions';
import Colors from '@/utils/colors';

export default {
    findSubjects(filters, page, pageSize, sortField, sortOrder) {
        return entityHelper.findEntity("/subjects", filters, page, pageSize, sortField, sortOrder);
    },
    getSubject(id) {
        return axios.get('/subjects/'+id);
    },
    getSubjects(id, cancelToken, viewAll = false) {
        let config = {};
        let idValue = "";

        if(id != null && !isNaN(id)) {
            idValue = id;
        }

        if (typeof(cancelToken) !== 'undefined') {
            config['cancelToken'] = cancelToken;
            config['viewAll'] = viewAll;
        }

        return axios.get('/subject/' + idValue, config);
    },
    getSubjectTypes(id){
        if(id === undefined){
            id = '';
        }
        return axios.get('/subjectType/'+id);
    },
    getSubjectTypesByStage(stageId){
        // console.log("getSubjectTypesByStage", stageId);
        let params = {stageId: stageId};
        return axios.get('/subjectType/',{params});
    },
    getSubjectForCourseStage(courseStage,year){
        let parameters = {};
        if(year !== undefined){
            parameters.year= year;
        }
        parameters.courseStage=courseStage;
        return axios.get('/subject',{params:parameters});
    },
    saveSubject(subject) {
        return entityHelper.saveEntity(subject, '/subjects');
    },
    saveSubjects(subjects) {
        return entityHelper.saveEntity(subjects, '/subjects');
    },
    createSubject(name, year = undefined) {
        let url = '/subjects';
        let subjectColor = Colors.getRandomColor();
        let subject = {
            'name': name,
            //'code': StringFunctions.generateCodeFrom(name, 10), lo comento para que se encargue el back de generar el código
            'color': subjectColor.value
        };
        if(year != undefined){
            url += '?year='+year;
        }
        return entityHelper.saveEntity(subject, url);
    },
    deleteSubject(subject) {
        return entityHelper.deleteEntity(subject, '/subjects');
    },
    deleteSubjects(idsList) {
        return axios.post('/subjects/batch-delete', {
            list: idsList
        });
    },
    getSubjectsByParent(id) {
        return axios.get('/subject-children/' + id);
    },
    getSubjectsByCourseAndStage(stageId, courseId, cancelToken) {
        let config = {};
        let params = { stageId: stageId, courseId: courseId };

        config['params'] = params;
        if (typeof(cancelToken) !== 'undefined') {
            config['cancelToken'] = cancelToken;
        }

        return axios.get('/subjectsByCourseAndStage', config);
    },
    getSubjectsSchema(schema, courseStageId) {
        return axios.get(`/subjects-schema/${schema}/${courseStageId}`);
    }
}
