import $ from 'jquery';

export default  {
    getColors() {
        let colors = [
            //{ value: '#6e4b3f', label: 'cocoa'          },
            //{ value: '#A52A2A', label: 'brown'        },
            //{ value: '#8B4513', label: 'saddleBrown'        },
            //{ value: '#A0522D', label: 'siena'        },
            { value: '#D2691E', label: 'chocolate'        },
            { value: '#CD853F', label: 'peru'        },
            { value: '#F4A460', label: 'sandy-brown'        },
            { value: '#DAA520', label: 'golden-rod'        },
            { value: '#D2B48C', label: 'tan'        },
            { value: '#DEB887', label: 'burly-wood'        },
            { value: '#FFDAB9', label: 'peach-puff'     },
            { value: '#FFE4B5', label: 'moccasin' },
            { value: '#BC8F8F', label: 'rosy-brown'        },
            { value: '#DB7093', label: 'pale-violedred'    },
            { value: '#d33e3e', label: 'reddish'        },
            //{ value: '#b22222', label: 'firebrick'     },
            { value: '#DC143C', label: 'crimson'     },
            //{ value: '#ff0000', label: 'red'     },/* comento aquest perquè el color sembla un ERROR */
            { value: '#f52323', label: 'tomato'         },
            { value: '#ff3501', label: 'red-orange'     },
            { value: '#F08080', label: 'light-coral'},
            { value: '#FA8072', label: 'salmon'},
            { value: '#E9967A', label: 'dark-salmon'},
            { value: '#FFA07A', label: 'light-salmon'},
            { value: '#FF7F50', label: 'coral'     },
            { value: '#f47317', label: 'orange'         },
            { value: '#f5a623', label: 'orangey-yellow' },
            { value: '#f1c40f', label: 'golden-rod'     },
            { value: '#FFD700', label: 'gold'     },
            { value: '#FFFF00', label: 'yellow'     },
            { value: '#EEE8AA', label: 'pale-goldenrod'     },
            { value: '#F0E68C', label: 'khaki'     },
            { value: '#cedc4d', label: 'off-yellow'     },
            { value: '#B8E236', label: 'olive-drab' },
            { value: '#B2F556', label: 'dark-olivegreen' },
            { value: '#BDB76B', label: 'dark-khaki'     },
            { value: '#808000', label: 'olive' },
           // { value: '#18733f', label: 'tree-green'     },
            { value: '#27ae60', label: 'jade-green'     },
            { value: '#3ed396', label: 'weird-green'    },
           // { value: '#008000', label: 'Green' },
            { value: '#228B22', label: 'forest-green' },
            { value: '#2E8B57', label: 'sea-green' },
            { value: '#008B8B', label: 'dark-cyan' },
            { value: '#20B2AA', label: 'light-seagreen' },
            { value: '#5F9EA0', label: 'cadet-blue' },
            //{ value: '#00CED1', label: 'DarkTurquoise' },
            { value: '#48D1CC', label: 'medium-turquoise' },
            { value: '#66CDAA', label: 'medium-aquamarine' },
            { value: '#4dd0e1', label: 'bright-cyan'    },
            { value: '#64b5f6', label: 'sky-blue'       },
            { value: '#3498d0', label: 'cool-blue'      },
            { value: '#2368f5', label: 'clear-blue'     },
            //{ value: '#4169E1', label: 'RoyalBlue'},
            { value: '#4682B4', label: 'steel-blue'},
            { value: '#1E90FF', label: 'dodger-blu'},
            { value: '#00BFFF', label: 'deep-skyble'},
            { value: '#6495ED', label: 'cornfloweelblue'},
            { value: '#8576ed', label: 'cornflower'     },
            //{ value: '#6a1b9a', label: 'purple'         },
            //{ value: '#8A2BE2', label: 'BlueViolet'},
            { value: '#6A5ACD', label: 'slateblue'},
            { value: '#7B68EE', label: 'medium-slabeleblue'},
            //{ value: '#9932CC', label: 'DarkOrchi'},
            { value: '#9370DB', label: 'medium-purle'},
            { value: '#BA55D3', label: 'medium-orchid'},
            { value: '#d500f9', label: 'violet'         },
            { value: '#ab47bc', label: 'purpley-pink'   },
            { value: '#FF00FF', label: 'magenta'},
            { value:'#DA70D6', label: 'orchid'},
            { value: '#EE82EE', label: 'light-violet'    },
            { value: '#DDA0DD', label: 'plum'    },
            { value: '#D8BFD8', label: 'thistle'    },
           // { value: '#c2185b', label: 'lipstick'       },
            { value: '#FFB6C1', label: 'light-pink'    },
            { value: '#FF69B4', label: 'hot-pink'    },
            { value: '#f81c8d', label: 'strong-pink'    },
        ];
        return colors;
    },
    getColorName($strHexColor) {
        let colors = this.getColors();
        for (let i = 0; i < colors.length; i++) {
            if (colors[i].value == $strHexColor) {
                return colors[i].label;
            }
        }
        return "";
    },
    getColorNames() {
        let colors = this.getColors();
        let colorNames = [];

        for (let i = 0; i < colors.length; i++) {
            colorNames.push(colors[i].label);
        }
        return colorNames;
    },
    getColorValue($strNameColor) {
        let colors = this.getColors();

        for (let i = 0; i < colors.length; i++) {
            if (colors[i].label == $strNameColor) {
                return colors[i].value;
            }
        }
        return "";
    },
    getColorValues() {
        let colors = this.getColors();
        let colorValues = [];

        for (let i = 0; i < colors.length; i++) {
            colorValues.push(colors[i].value);
        }
        return colorValues;
    },
    getColorNameModule(value) {
        let colors = this.getColors();
        value = value * 3;
        if(colors[value%colors.length]){
            return colors[value%colors.length].label;
        }
        else{
            return '';
        }
    },
    getColorNameValue(value) {
        let colors = this.getColors();
        value = value * 3;
        if(colors[value%colors.length]){
            return colors[value%colors.length].value;
        }
        else{
            return '';
        }
    },
    getLightenDarkenColor(color, amount) {
        /* Més informació a https://css-tricks.com/snippets/javascript/lighten-darken-color/ */
        var usePound = false;

        if (color[0] == "#") {
            color = color.slice(1);
            usePound = true;
        }

        var num = parseInt(color, 16);

        var r = (num >> 16) + amount;

        if (r > 255) r = 255;
        else if  (r < 0) r = 0;

        var b = ((num >> 8) & 0x00FF) + amount;

        if (b > 255) b = 255;
        else if  (b < 0) b = 0;

        var g = (num & 0x0000FF) + amount;

        if (g > 255) g = 255;
        else if (g < 0) g = 0;

        return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
    },
    getRandomColor() {
        let colorsList = this.getColors();

        return colorsList[Math.floor(Math.random() * colorsList.length)];
    },
    hexToRgbA(hex, transparency = 1){
        let c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length === 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+transparency+')';
        }
    },
    mergeColors(color1, color2) {
        let hexToRgb = (hex) => {
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
          return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
          } : null;
        }
      
        let rgbToHex = (r, g, b) => {
          return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
        }
      
        let color1Rgb = hexToRgb(color1);
        let color2Rgb = hexToRgb(color2);
      
        let mergedRgb = {
          r: Math.round((color1Rgb.r + color2Rgb.r) / 2),
          g: Math.round((color1Rgb.g + color2Rgb.g) / 2),
          b: Math.round((color1Rgb.b + color2Rgb.b) / 2)
        };
      
        return rgbToHex(mergedRgb.r, mergedRgb.g, mergedRgb.b);
      }

}


