import cssVariables from "@/assets/css/variables.js";
import { mapState } from "vuex";

export default {
    beforeMount() {
        this.$store.dispatch("getYears", { force: false });
    },
    computed: {
        ...mapState({
            yearsStore: state => state.currentCenter.years,
        }),
    },
    methods: {
        getYearValidityByCode(yearCode) {
            let year = null;
            if(this.yearsStore && Array.isArray(this.yearsStore)){
                year = this.yearsStore.find(year => year.value === yearCode);
            }
            return year ? year.validity : null;
        },
        /**
         * Función para unificar los toast, y que no se repita a todos los archivos
         * @param {String} body
         * @param {String} title
         * @param {String} variant primary, secondary, danger, warning, success, info
         * @param {Boolean} [noAutoHide = false]
         * @param {Number} [autoHideDelay = 5000]
         * @param {String} [toaster = 'b-toaster-top-right']
         */
        toast(body, title, variant = "success", noAutoHide = false, toaster = 'b-toaster-top-right', autoHideDelay = 5000){
            this.$bvToast.toast(body, {
                title: title,
                toaster: toaster,
                noAutoHide: noAutoHide,
                autoHideDelay: autoHideDelay,
                variant: variant
            })
        },
        /**
         * Función para unificar los loading de los selects y no estar repitiendo la validación
         * @param {String} ref Referencia del selector
         * @param {Boolean} loading true o false
         */
        setSelectLoading(ref, loading){
            //console.log("setLoading:", ref, loading)
            if(this.$refs[ref]){
                this.$refs[ref].setLoading(loading);
            }
        },
        /**
         * Función para no estar llamando siempre al store y no confundirnos
         * @param {Boolean} state true o false
         */
        setStoreLoading(state){
            this.$store.commit("setLoading", state);
        },
        largeText(ref){
            if(ref in this.$refs){
                var text = this.$refs[ref];

                if (text?.scrollWidth > text?.clientWidth || text?.innerText?.length+120 > text?.clientWidth || text?.textContent?.length+120 > text?.clientWidth || text?.innerHTML?.length+120 > text?.clientWidth) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        },
        goToError(){
            this.$router.push({name: 'error'})
        },
        /**
         * Comparació en bytes
         * @param {Number} fileSize A bytes
         * @param {Number} maxSize A bytes DEFAULT 10MB
         * @returns 
         */
        canUploadFileMaxSize(fileSize, maxSize = 10000000){
            return fileSize <= maxSize;
        },
        getThemeColor(color = "brand1"){
            let theme = localStorage.getItem("template")?.toLowerCase() ?? "ieduca";
            if(cssVariables && Object.keys(cssVariables).length > 0){
                let themes = Object.keys(cssVariables).map(n => n.split("_")[0]);
                let actualTheme = "ieduca";
                if(themes.findIndex(t => t == theme) > -1){
                    actualTheme = theme;
                }
                return cssVariables[actualTheme+"_template"][color];
            }
        },
        /**
         * Formatejar la moneda segons l'idioma i el tipus de moneda
         * @param {string} currency 
         * @param {int} value 
         * @returns 
         */
        currencyFormatter(currency, value, forceCode = false) {
            let locale = this.$i18n.locale;
            let showCode = "symbol";
            switch (locale) {
                case 'es':
                case 'ca':
                    locale = 'es-'+this.$i18n.locale.toUpperCase();
                    break;
                default:
                    let newLocal = this.$i18n.locale.split("_");
                    locale = newLocal[0]+"-"+newLocal[1].toUpperCase();
                    if(forceCode){
                        showCode = "code";
                    }
                    break;
            }
            if(!this.lodash.isNil(currency) && !this.lodash.isNil(value)){
                let formatter = new Intl.NumberFormat(locale, {
                    style: 'currency',
                    minimumFractionDigits: 2,
                    currency,
                    currencyDisplay: showCode
                });
                return formatter.format(value)
            } else {
                return value + " " + currency;
            }
        }
    },
}
