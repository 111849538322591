<template>
  <nav aria-label="breadcrumb" id="breadCrumbSection">
    <ol class="breadcrumb align-items-center">
      <li class="d-inline-flex align-items-center">
        <router-link v-if="isAdminMenu" :to="{name:'config'}" class="d-inline-flex align-items-center">
            <i class="icon icon-cog"></i>
            <span v-if="breadCrumbList.length == 1" class="homeAndConfig pl-1">{{$t("configuration")}}</span>
        </router-link>
        <router-link v-if="isUserMenu" :to="{ name: 'root'}" class="d-inline-flex align-items-center">
            <i class="icon icon-house-alt"></i>
            <span v-if="breadCrumbList.length <= 1" class="homeAndConfig pl-1">{{$t("home")}}</span>
        </router-link>
      </li>
      <li v-for="(breadcrumb, idx) in breadCrumbList" :key="idx"
          :class="['breadcrumb-item', 'd-inline-flex', 'align-items-center', {'breadcrumb-clickable': breadcrumb.link}]">
        <a @click="routeTo(idx)">{{ breadcrumb.name }}</a>
      </li>
    </ol>
    <div class="row breadcrumb-titel">
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 no-padding">
        <span class="title" v-html="pageTitle"></span>
        <span :class="'icon '+iconClass" v-b-tooltip="iconTooltip" @click="goToIconLink"></span>
        <span class="subtitle" v-if="pageSubtitle" v-html="pageSubtitle"></span>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 wrapper-yearComponent pl-0">
        <year-component 
          v-if="showCourseSelector" 
          class="breadcrumb-yearComponent" 
          :never-show-errors="true" 
          :allow-void="false" 
          :display-if-one="true"
          :save-to-store="false"
          :yearDefault="breadcrumbYear?.code"
          @year:selected="setBreadcrumYear"
        />
      </div>
    </div>
  </nav>
</template>

<script>
    // https://medium.com/@fagnersaraujo/automated-breadcrumbs-with-vuejs-7e1051de8028

    import YearComponent from "./YearComponent";
    import cutFunctions from '@/filters/cutFunctions';

    export default {
        name: 'Breadcrumb',
        components: {
            YearComponent
        },
        data() {
            return {
            }
        },
        computed: {
            isAdminMenu() {
                return this.$store.state.type === this.$store.state.menuTypes.ADMIN;
            },
            isUserMenu() {
                return this.$store.state.type === this.$store.state.menuTypes.USER;
            },
            breadCrumbList() {
                return this.$store.state.breadcrumbs;
            },
            pageTitle() {
                return this.$store.state.pageTitle;
            },
            pageSubtitle() {
                return this.$store.state.pageSubtitle;
            },
            showCourseSelector(){
                return this.$store.state.showCourseSelector;
            },
            iconClass(){
                return this.$store.state.breadCrumbTitleIcon.class;
            },
            iconLink(){
              return this.$store.state.breadCrumbTitleIcon.link;
            },
            iconTooltip(){
              return this.$store.state.breadCrumbTitleIcon.tooltip;
            },
            openIconLinkInNewTab(){
              return this.$store.state.breadCrumbTitleIcon.newTab;
            },
            breadcrumbYear(){
              return this.$store.state.breadcrumbYear;
            }
        },
        methods: {
            routeTo(pRouteTo) {
                let route = {};
                if (this.breadCrumbList[pRouteTo].link) {
                    route.name = this.breadCrumbList[pRouteTo].link;
                }
                if (this.breadCrumbList[pRouteTo].params) {
                    route.params = this.breadCrumbList[pRouteTo].params;
                }

                if (!this.lodash.isEmpty(route)) {
                    if(route.name === this.$router.history.current.name){
                      let menuReload = this.$store.getters.getMenuReload +1;
                      this.$store.commit("setMenuReload", menuReload);
                    }
                    // console.log("router.push", route);
                    this.$router.push(route);
                    this.$store.commit('splice', pRouteTo);
                }
            },
            isLast(index) {
                //console.log("computing index: " + index + " of " + this.$store.state.breadcrumbs.length);
                return this.$store.state.breadcrumbs.length == index + 1;
            },
            goToIconLink(){
                if(this.iconLink){
                  if(this.openIconLinkInNewTab){
                    let route = this.$router.resolve(this.iconLink);
                    window.open(route.href);
                  }
                  else{
                    this.$router.push(this.iconLink);
                  }
                }
            },
            setBreadcrumYear(year){
              	this.$store.commit('setBreadcrumbYear', year);
            }
        }
    }
</script>

<style scoped lang="scss">
  @import "../assets/css/variables.scss";

  #breadCrumbSection {
    /*position: sticky;*/
    width: 100%;
    z-index: 15;
    top: $heightHeaderDesktop;
    padding: 0.4rem 0rem 0rem 1rem;
    background-color: $breadcrumBackground !important;
    border-top: 1px solid $borderTopMenu;
    .breadcrumb li {
      margin-top: 0;
      margin-bottom: 0;
    }
    
    .icon{
      font-size: 16px;
    }
  }

  .breadcrumb {
    background-color: $breadcrumBackground !important;
    padding: 0rem !important;
    margin: 0rem !important;
  }

  .breadcrumb-item + .breadcrumb-item:before {
    content: "/";
    font-size: 1.1rem;
  }

  .breadcrumb-clickable {
    color: $breadcrumClickable;
    cursor: pointer;
    &:hover {
      color: $brand2;
    }
  }

  .breadcrumb-root {
    color: $blackIeduca;
    font-size: 1.1rem;
    padding-left: 1.1rem;
  }

  // #breadCrumbSection .breadcrumb li:first-child {
  //   /*padding-top: 0.28rem;*/
  // }
  span{
    &.disabled {
      color: $brand2;
    }
  
    &.title {
      font-size: 1.6rem;
      // font-weight: 600;
      @media (max-width: 480px) {
        font-size: 1.5rem;
      }
    }
  
    &.subtitle {
      margin-left: 10px;
      font-size: 1.2rem;
      @media (max-width: 480px) {
        font-size: 1.05rem;
      }
    }
  }
  .breadcrumb-titel{ margin-top: -5px; }

  @media (max-width: 575px) {
    span.subtitle {
      // margin-left: 0 !important;
      // display: block;
      margin-bottom: 24px;
    }
  }

  @media (max-width: 767px) {
    #breadCrumbSection {
      padding: 1rem 0rem 0rem 1rem;
    }
  }
  @media (max-width: 480px) {
    #breadCrumbSection {
      padding: 0.2rem 0rem 0rem 0.5rem;
    }
  }
</style>

