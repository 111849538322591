import axios from 'axios'
import entityHelper from "@/api/entityHelper";
import qs from 'qs';

export default {
    getClasses(filters, options, cancelToken) {
        let config = {};
        let params = {};

        if (filters != null) {
            params['filters'] = filters;
        }

        if (options != null) {
            params['options'] = options;
        }

        config['params'] = params;
        config['paramsSerializer'] = function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }

        if (cancelToken != null) {
            config['cancelToken'] = cancelToken.token;
        }
        return axios.get("/classes", {params: params});
    },
    getClassesSubject(filters, options, cancelToken){
        if(options !== undefined){
            options['groupedBySubject'] = true; //Sempre que es crida aquesta funció es per recollir les classes per matèries
        } else {
            options = { 'groupedBySubject': true };
        }
        return this.getClasses(filters, options, cancelToken);
    },
    getClasseSessions(filters, options, cancelToken){
        let config = {};
        let params = {};

        if (filters != null) {
            params['filters'] = filters;
        }

        if (options != null) {
            params['options'] = options;
        }

        config['params'] = params;
        config['paramsSerializer'] = function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }

        if (cancelToken != null) {
            config['cancelToken'] = cancelToken.token;
        }
        return axios.get("/classe-sessions",config);
    },
    getClassesFollowup(filters, options, cancelToken) {
        let config = {};
        let params = {};

        if (filters != null) {
            params['filters'] = filters;
        }

        if (options != null) {
            params['options'] = options;
        }

        config['params'] = params;
        config['paramsSerializer'] = function (params) {
            return qs.stringify(params, { arrayFormat: 'repeat' });
        }

        if (cancelToken != null) {
            config['cancelToken'] = cancelToken.token;
        }

        return axios.get("/classes/followup", config);
    },
    getStudents(currentClass, cancelToken, filteredYear = null) {
        //console.log("classes.js - getStudents!!")
        let config = {
            params: {
                'classeId': currentClass.classeId,
                'date': currentClass.date,
                'startTime': currentClass.startTime,
                'endTime': currentClass.endTime,
                'groupId': currentClass.group != null ? currentClass.group.id : null,
                'studentId': currentClass.student != null ? currentClass.student.id : null,
                'subjectId': currentClass.subject != null ? currentClass.subject.id : null,
                'format': currentClass?.format ?? null
            }
        };

        if(filteredYear != null){
            config.params.year = filteredYear;
        }

        if (typeof cancelToken != 'undefined' && cancelToken != null) {
            config['cancelToken'] = cancelToken.token;
        }

        return axios.get("/classes/students", config);
    },
    getClassInfo(date, timetableSlotId, groupId, subjectId, courseStageId, classeId) {
        return axios.get("/classes/info", {
            params: {
                'date': date,
                'timeId': timetableSlotId,
                'groupId': groupId,
                'subjectId': subjectId,
                'courseStageId': courseStageId,
                'classId': classeId
            }
        });
    },
    saveHomework(homeworkInfo) {
        return axios.post("/classes/homework", {
            'groupId': homeworkInfo.currentClass.group ? homeworkInfo.currentClass.group.id : null,
            'startDate': homeworkInfo.startDate,
            'startTime': homeworkInfo.startTime,
            'endDate': homeworkInfo.endDate,
            'endTime': homeworkInfo.endTime,
            'title': homeworkInfo.title,
            'description': homeworkInfo.description
        });
    },
    getClassFullInfo(groupId, classInfo, studentsIds, page, limit, filters) {
        let pageValue = page || 1;
        let limitValue = limit || 20;
        let filtersValue = filters || [];
        // console.log("getClassFullInfo", classInfo);
        return axios.get("/classes/full_info", {
            params: {
                'date': (classInfo ? classInfo.date : ''),
                'timeId': (classInfo && classInfo.timetableSlot? classInfo.timetableSlot.id : 0),
                'groupId': (classInfo && classInfo.group ? classInfo.group.id : groupId),
                'subjectId': (classInfo && classInfo.subject? classInfo.subject.id : 0),
                'courseStageId': (classInfo && classInfo.courseStage? classInfo.courseStage.id : 0),
                'classeId': (classInfo ? classInfo.classeId : 0),
                'studentsIds': studentsIds,
                'teacherId': 0,
                'limit': limitValue,
                'page': pageValue,
                'filters': filtersValue
            }
        });
    },
    linkFileToClass(fileName, fileDescription, route, groupId, subjectId, courseStageId, classeId, session) {
        let params = {
            'fileName': fileName,
            'fileDescription': fileDescription,
            'route': route,
            'groupId': groupId,
            'subjectId': subjectId,
            'courseStageId': courseStageId
        };
        if (classeId != null) {
            params['classeId'] = classeId;
        }
        if (session != null) {
            params['session'] = session;
        }
        return axios.post("/classDocuments/link-file-class", params);
    },
    unlinkFileFromClass(classDocumentId) {
        return axios.post("/classDocuments/unlink-file-class", {
            'classDocumentId': classDocumentId
        });
    },
    saveSessionClass(currentClass) {
        return entityHelper.saveEntity(currentClass, '/classes');
    },
    updateClass(currentClass) {
        return entityHelper.saveEntity(currentClass, '/classes/' + currentClass.id);
    },
    removeSessionClass(currentSession) {
        return axios.post("/classes/removeSession", {'entity': currentSession});
    },
    addStudentsToClasse(classe, students) {
        return axios.post("/classes/addStudents", { 'classe': classe, 'students': students });
    },
    moveClasse(classeId, dayId) {
        return axios.post("/classes/move", {'classeId': classeId, 'dayId': dayId});
    },
    saveClasse(classe) {
        return axios.post("/classes", {'entity': classe});
    },
    /*saveClasseByGroupCourseStageProgram(groupCourseStageId, programId){
        return axios.post("/saveClasseByParams", {'groupCourseStageId': groupCourseStageId, 'programId': programId});
    },*/
    deleteClasse(id, force = false, forAllUsers = false){
        let params = {
            'force': force,
            'forAllUsers': forAllUsers
        }
        return axios.delete("/classes/complete/"+ id, {params: params});
    },
    getClassDependencies(classId) {
        return axios.get(`/class/dependencies/${classId}`);
    },
    saveClasseSession(classe) {
        return axios.post("/classe-sessions", {'entity': classe});
        /**
         * "classe": { "id": 51 },
            "date": "26/08/2022",
            "timetableSlot": { "id": 52 }
         */
    },
    getClasseStudents(classeId) {
        return axios.get('/classe-members/' + classeId + '/student');
    },
    getClasseParents(classeId) {
        return axios.get('/classe-members/' + classeId + '/parent');
    },
    getClasseTeachers(classeId) {
        return axios.get('/classe-members/' + classeId + '/teacher');
    },
    saveClassesTeachers(classesId, teachers) {
        let params = {
            classesId: classesId,
            teachers: teachers
        }
        return axios.post('/classes/classe-teachers', {params: params});
    },
    saveNewClasseTeachers(groupsId, teachers, programId) {
        let params = {
            groupsId: groupsId,
            programId: programId,
            teachers: teachers
        }
        return axios.post('/classes/new-classe-teachers', {params: params});
    },
    saveClassePersonPreferences(classeId, personId, preferences) {
        let params = {
            personId: personId,
            preferences: preferences
        }
        return axios.post('/classes/'+classeId+'/person-preferences', {params: params})
    },
    getInfoSession(sessionId){
        return axios.get('/classe-session/' + sessionId);
    },
    getClasseIsFromTimetable(classeId){
        return axios.get('/classeIsFromTimetable/' + classeId);
    },
}
