import axios from 'axios';
import entityHelper from '@/api/entityHelper'

export default {
    getEnrollment(studentName,stage,courseStage,groupCourseStage,program,schemaName,includeCanceled, year){
        let params = {}
        if(groupCourseStage !== undefined){
            params['group_course_stage_id']=groupCourseStage;
        }
        if(program !== undefined){
            params['program_id']=program;
        }
        if(studentName !== undefined){
            params['student_name']=studentName;
        }
        if(stage !== undefined){
            params['stage_id']=stage;
        }
        if(courseStage !== undefined){
            params['course_stage_id']=courseStage;
        }
        if(schemaName != null && typeof schemaName!=='undefined' ){
            params['changeYear']=schemaName;
        }
        if(includeCanceled !== undefined){
            params['includeCanceled']=includeCanceled;
        }
        if(year !== undefined){
            params['year']=year;
        }
        return axios.get('/enrollment',{params});
    },
    saveEnrolment(enrolment) {
        // console.log("---> API saveEnrolment - enrolment: ",enrolment);
        if(enrolment.state!=null && enrolment.state!=undefined && typeof enrolment.state != "string"){
            var n = enrolment.state.value.lastIndexOf('.');
            let state = enrolment.state.value.substring(n + 1);
            if(state!=undefined && state!=''){ enrolment.state = state; }
        }
        return entityHelper.saveEntity(enrolment, '/enrolment');
    },
    getEnrolments(filters, page, pageSize, orderField, orderMode){
        return entityHelper.findEntity("/enrolments", filters, page, pageSize, orderField, orderMode);
    },
    saveEnrolmentProgram(enrolmentProgram){
        return entityHelper.saveEntity(enrolmentProgram, '/enrolment/program');
    },
    deleteEnrolmentProgram(enrolmentProgram){
        return entityHelper.deleteEntity(enrolmentProgram, '/enrolment/program');
    },
    getEnrolmentPDF(enrolmentId, templateId, schemaName) {
        let url = `/enrolments/${enrolmentId}/pdf/template/${templateId}/`;

        if (schemaName != null && schemaName !== "") {
            url += schemaName;
        }

        return axios.get(url, {
            responseType: 'arraybuffer'
        });
    },
    getEnrolmentsPDF(enrolmentId, templateId, schemaName, personId){
        return axios.get('/enrolments/pdf/'+personId+'/'+schemaName,{
            responseType: 'arraybuffer'
        });
    },
    getEnrollmentById(enrolmentId, schema){
        let params = {schema: schema};
        return axios.get('/enrolment/'+enrolmentId, {params});
    },
    saveEnrolmentsPrograms(addProgramsIds, deleteProgramsIds, year){
        let params = {addProgramsIds: addProgramsIds, deleteProgramsIds: deleteProgramsIds};
        return axios.post('/enrolments/programs' + "?year=" + year, {params});
    },
    saveEnrolmentProgramsDetail(enrolmentId, enrolmentPrograms, schemaName){
        let params = {enrolmentId: enrolmentId, enrolmentPrograms: enrolmentPrograms, schemaName: schemaName};
        return axios.post('enrolments/programs/detail', {params});
    },
    saveEnrolmentsProgramsValidatedNotes(enrolmentProgramsValidatedNotes, schemaName){
        let params = {enrolmentProgramsValidatedNotes: enrolmentProgramsValidatedNotes, schemaName: schemaName};
        return axios.post('/enrolments/programs/validatedNotes', {params})
    },
    deleteEnrolment(id, schema){
        let params = {schema: schema};
        return axios.delete('/enrolment/'+id, {params});
    },
    sendEnrolmentPDF(personId, persons, schema = null, enrolmentId = null, courseStageId = null){
        let params = {persons: persons, enrolmentId: enrolmentId, courseStageId: courseStageId};
        return axios.post('/enrolments/'+personId+'/'+schema+'/sendPdf', {params});
    },
    getEnrolmentsExported(filters, sortField, sortOrder){
        return entityHelper.findEntity("/enrolments/export", filters, 1, null, sortField, sortOrder);
    },
    getNotPromoted(groupCourseStageId, convocatoryId, year){
        let params = {
            groupCourseStageId: groupCourseStageId,
            convocatoryId: convocatoryId,
            year: year
        }
        return axios.get('/enrollment/not-promoted', {params});
    },
    getNotPromotedByPersons(year, personIds){
        let params = {
            personIds: personIds,
            year: year
        }
        return axios.get('/enrollment/not-promoted/persons', {params});
    },
    saveNotPromoted(groupCourseStageId, convocatoryId, year, studentId, value){
        let params = {
            groupCourseStageId: groupCourseStageId,
            convocatoryId: convocatoryId,
            year: year,
            personId: studentId,
            mark: value
        }
        return axios.post('/enrollment/not-promoted', {params});
    },
    getEnrolmentsStagesPDF(){
        return axios.get('/enrolments/stagesEnrolmentsPDF',{
            responseType: 'arraybuffer'
        });
    },
}
