import centrifugoAPI from "@/api/centrifuge/centrifugo";
import { Centrifuge } from 'centrifuge';

class CentrifugeService {
    token = null;
    tokenPromise = null;
    connection = null;
    connectionPromise = null;
    isConnected = false;

    constructor() {
        // És un singleton per evitar duplicar connexions
        if (!CentrifugeService.instance) {
            CentrifugeService.instance = this;
        }
        return CentrifugeService.instance;
    }

    async getToken() {
        // Si ja tenim el token, no cal tornar a demanar-lo
        // Al ser una crida asíncrona, si es criden dues vegades seguides, no es tornarà a demanar el token
        if (!this.tokenPromise) {
            this.tokenPromise = centrifugoAPI.getToken().then(token => {
                this.token = token.token;
                this.tokenPromise = null;
                return token;
            });
        }
        return this.tokenPromise;
    }

    async getConnection() {

        // Si ja tenim la connexió, la retornem
        if (this.connection && this.isConnected) {
            return this.connection;
        }

        // Al ser una crida asíncrona, si es criden dues vegades seguides, no es tornarà a demanar la connexió
        if (!this.connectionPromise) {
            this.connectionPromise = (async () => {
                if (!this.token) {
                    this.token = await this.getToken();
                }

                this.connection = new Centrifuge("wss://websocket.ieduca2.com/connection/websocket", this.token);

                this.connection.on('connecting', (ctx) => {
                    // console.log('Connecting...', ctx);
                }).on('connected', (ctx) => {
                    // console.log('connected', ctx);
                    this.isConnected = true;
                    // console.log('Connected', ctx);
                }).on('disconnected', async (ctx) => {
                    // console.log('Disconnected', ctx);
                    this.isConnected = false;
                    // Si el token no és vàlid, el tornem a demanar una sola vegada
                    if (ctx.code === 3500) {
                        this.token = await this.getToken();
                        this.connection.setToken(this.token);
                        this.connection.connect();
                    }
                }).connect();

                return this.connection;
            })();
        }

        return this.connectionPromise;
    }
}
const instance = new CentrifugeService();

export default instance;
