import entityHelper from '@/api/entityHelper'
import axios from 'axios';

export default {

  findSpaces(filters, page, pageSize, orderField, orderMode){
    return entityHelper.findEntity("/spaces", filters, page, pageSize, orderField, orderMode);
  },
  saveSpace(space) {
    return entityHelper.saveEntity(space, '/spaces');
  },
  updateSpace(space) {
    return entityHelper.updateEntity(space, '/spaces');
  },
  deleteSpace(space) {
    return entityHelper.deleteEntity(space, '/spaces');
  },
  getSpaces(){
    return axios.get('/spaces/all');
  },
  getFreeSpaces(date, hourIni, hourEnd){
    let params = {
      date: date,
      hourIni: hourIni,
      hourEnd: hourEnd
    };
    return axios.get('/spaces/free', {params});
  }

}
