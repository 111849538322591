export default{
    "country.004": "Afganistán",
    "country.248": "Islas Áland",
    "country.008": "Albania",
    "country.276": "Alemania",
    "country.012": "Argel",
    "country.020": "Andorra",
    "country.024": "Angola",
    "country.660": "Anguila",
    "country.010": "Antártida",
    "country.028": "Antigua y Barbuda",
    "country.682": "Arabia Saudita",
    "country.032": "Argentina",
    "country.051": "Armenia",
    "country.533": "Aruba",
    "country.036": "Australia",
    "country.040": "Austria",
    "country.031": "Azerbaiyán",
    "country.044": "Bahamas",
    "country.048": "Bahréin",
    "country.050": "Bangladesh",
    "country.052": "Barbados",
    "country.056": "Bélgica",
    "country.084": "Belice",
    "country.204": "Benin",
    "country.060": "Bermudas",
    "country.064": "Bhután",
    "country.112": "Belarús",
    "country.068": "Bolivia",
    "country.535": "Bonaire, Sint Eustatius y Saba; Caribe Neerlandés; Islas BES",
    "country.070": "Bosnia y Herzegovina",
    "country.072": "Botsuana",
    "country.074": "Isla Bouvet",
    "country.076": "Brasil",
    "country.096": "Brunéi",
    "country.100": "Bulgaria",
    "country.854": "Burkina Faso",
    "country.108": "Burundi",
    "country.136": "Islas Caimán",
    "country.116": "Camboya",
    "country.120": "Camerún",
    "country.124": "Canadá",
    "country.132": "Cabo Verde",
    "country.140": "República Centro-Africana",
    "country.162": "Islas Christmas",
    "country.166": "Islas Cocos",
    "country.170": "Colombia",
    "country.174": "Comoros",
    "country.178": "Congo",
    "country.180": "Congo, República Democrática del",
    "country.184": "Islas Cook",
    "country.408": "Corea del Norte",
    "country.410": "Corea del Sur",
    "country.384": "Costa de Marfil",
    "country.188": "Costa Rica",
    "country.191": "Croacia",
    "country.192": "Cuba",
    "country.531": "Curazao",
    "country.208": "Dinamarca",
    "country.262": "Yibuti",
    "country.212": "Domínica",
    "country.214": "República Dominicana",
    "country.818": "Egipto",
    "country.218": "Ecuador",
    "country.784": "Emiratos Árabes Unidos",
    "country.232": "Eritrea",
    "country.703": "Eslovaquia",
    "country.705": "Eslovenia",
    "country.724": "España",
    "country.840": "Estados Unidos de América",
    "country.233": "Estonia",
    "country.231": "Etiopía",
    "country.234": "Islas Faroe",
    "country.242": "Fiji",
    "country.608": "Filipinas",
    "country.246": "Finlandia",
    "country.250": "Francia",
    "country.266": "Gabón",
    "country.270": "Gambia",
    "country.268": "Georgia",
    "country.239": "Georgia del Sur e Islas Sandwich del Sur",
    "country.288": "Ghana",
    "country.292": "Gibraltar",
    "country.300": "Grecia",
    "country.308": "Granada",
    "country.304": "Groenlandia",
    "country.312": "Guadalupe",
    "country.254": "Guayana Francesa",
    "country.316": "Guam",
    "country.320": "Guatemala",
    "country.831": "Guernsey",
    "country.324": "Guinea",
    "country.624": "Guinea-Bissau",
    "country.226": "Guinea Ecuatorial",
    "country.328": "Guayana",
    "country.332": "Haití",
    "country.334": "Islas Heard y McDonald",
    "country.340": "Honduras",
    "country.344": "Hong Kong",
    "country.348": "Hungría",
    "country.887": "Yemen",
    "country.833": "Isla de Man",
    "country.581": "Islas Periféricas Menores de los EUA; Estados Unidos de América (islas menores alejadas) ",
    "country.356": "India",
    "country.360": "Indonesia",
    "country.364": "Irán",
    "country.368": "Irak",
    "country.372": "Irlanda",
    "country.352": "Islandia",
    "country.376": "Israel",
    "country.380": "Italia",
    "country.388": "Jamaica",
    "country.392": "Japón",
    "country.832": "Jersey",
    "country.400": "Jordania",
    "country.398": "Kazajstán",
    "country.404": "Kenia",
    "country.417": "Kirguistán",
    "country.296": "Kiribati",
    "country.414": "Kuwait",
    "country.418": "Laos",
    "country.426": "Lesotho",
    "country.428": "Letonia",
    "country.422": "Líbano",
    "country.430": "Liberia",
    "country.434": "Libia",
    "country.438": "Liechtenstein",
    "country.440": "Lituania",
    "country.442": "Luxemburgo",
    "country.446": "Macao",
    "country.807": "Macedonia",
    "country.450": "Madagascar",
    "country.458": "Malasia",
    "country.454": "Malawi",
    "country.462": "Maldivas",
    "country.466": "Mali",
    "country.470": "Malta",
    "country.238": "Islas Malvinas",
    "country.580": "Mariannes Septentrionales, islas; Mariannes del Norte, islas; Marianes del Norte, islas",
    "country.504": "Marruecos",
    "country.584": "Islas Marshall",
    "country.474": "Martinica",
    "country.480": "Mauricio",
    "country.478": "Mauritania",
    "country.175": "Mayotte",
    "country.484": "México",
    "country.583": "Micronesia",
    "country.508": "Mozambique",
    "country.498": "Moldova",
    "country.492": "Mónaco",
    "country.496": "Mongolia",
    "country.499": "Montenegro",
    "country.500": "Montserrat",
    "country.104": "Myanmar",
    "country.516": "Namibia",
    "country.520": "Nauru",
    "country.524": "Nepal",
    "country.558": "Nicaragua",
    "country.562": "Níger",
    "country.566": "Nigeria",
    "country.570": "Niue",
    "country.574": "Islas Norkfolk",
    "country.578": "Noruega",
    "country.540": "Nueva Caledonia",
    "country.554": "Nueva Zelanda",
    "country.512": "Omán",
    "country.528": "Países Bajos",
    "country.586": "Pakistán",
    "country.585": "Islas Palaos",
    "country.275": "Palestina",
    "country.591": "Panamá",
    "country.598": "Papúa Nueva Guinea",
    "country.600": "Paraguay",
    "country.604": "Perú",
    "country.612": "Islas Pitcairn",
    "country.258": "Polinesia Francesa",
    "country.616": "Polonia",
    "country.620": "Portugal",
    "country.630": "Puerto Rico",
    "country.634": "Qatar",
    "country.826": "Reino Unido",
    "country.638": "Reunión",
    "country.642": "Rumanía",
    "country.643": "Rusia",
    "country.646": "Ruanda",
    "country.732": "Sahara Occidental",
    "country.659": "San Cristóbal y Nieves",
    "country.662": "Santa Lucía",
    "country.666": "San Pedro y Miquelón",
    "country.670": "San Vicente y las Granadinas",
    "country.652": "San Bartolomé",
    "country.663": "Saint-Martin; Saint-Martin (Antillas Francesas)",
    "country.090": "Islas Solomón",
    "country.222": "El Salvador",
    "country.882": "Samoa",
    "country.016": "Samoa Americana",
    "country.674": "San Marino",
    "country.654": "Santa Elena",
    "country.678": "Santo Tomé y Príncipe",
    "country.686": "Senegal",
    "country.688": "Serbia y Montenegro",
    "country.690": "Seychelles",
    "country.694": "Sierra Leona",
    "country.702": "Singapur",
    "country.534": "Sint Maarten",
    "country.760": "Siria",
    "country.706": "Somalia",
    "country.144": "Sri Lanka",
    "country.710": "Sudáfrica",
    "country.736": "Sudán",
    "country.728": "Sudán del Sur; República del Sudán del Sur",
    "country.752": "Suecia",
    "country.756": "Suiza",
    "country.740": "Surinam",
    "country.744": "Islas Svalbard y Jan Mayen",
    "country.748": "Suazilandia",
    "country.762": "Tayikistán",
    "country.764": "Tailandia",
    "country.158": "Taiwán",
    "country.834": "Tanzania",
    "country.086": "Territorio Británico del Océano Índico",
    "country.260": "Territorios Australes Franceses",
    "country.626": "Timor-Leste",
    "country.768": "Togo",
    "country.772": "Tokelau",
    "country.776": "Tonga",
    "country.780": "Trinidad y Tobago",
    "country.788": "Túnez",
    "country.795": "Turkmenistán",
    "country.796": "Islas Turcas y Caicos",
    "country.792": "Turquía",
    "country.798": "Tuvalu",
    "country.148": "Chad",
    "country.203": "República Checa",
    "country.804": "Ucrania",
    "country.800": "Uganda",
    "country.858": "Uruguay",
    "country.860": "Uzbekistán",
    "country.548": "Vanuatu",
    "country.336": "Ciudad del Vaticano",
    "country.862": "Venezuela",
    "country.092": "Islas Vírgenes Británicas",
    "country.850": "Islas Vírgenes de los Estados Unidos de América",
    "country.704": "Vietnam",
    "country.876": "Wallis y Futuna",
    "country.152": "Chile",
    "country.156": "China",
    "country.196": "Chipre",
    "country.894": "Zambia",
    "country.716": "Zimbabue",
    "town.724.02": "Albacete",
    "town.724.03": "Alicante",
    "town.724.04": "Almería",
    "town.724.01": "Álava",
    "town.724.33": "Asturias",
    "town.724.05": "Ávila",
    "town.724.06": "Badajoz",
    "town.724.07": "Islas Baleares",
    "town.724.08": "Barcelona",
    "town.724.48": "Vizcaya",
    "town.724.09": "Burgos",
    "town.724.10": "Cáceres",
    "town.724.11": "Cádiz",
    "town.724.39": "Cantabria",
    "town.724.12": "Castellón",
    "town.724.13": "Ciudad Real",
    "town.724.14": "Córdoba",
    "town.724.15": "La Coruña",
    "town.724.16": "Cuenca",
    "town.724.20": "Gipuzkoa",
    "town.724.17": "Girona",
    "town.724.18": "Granada",
    "town.724.19": "Guadalajara",
    "town.724.21": "Huelva",
    "town.724.22": "Huesca",
    "town.724.23": "Jaén",
    "town.724.24": "León",
    "town.724.25": "Lleida",
    "town.724.27": "Lugo",
    "town.724.28": "Madrid",
    "town.724.29": "Málaga",
    "town.724.30": "Murcia",
    "town.724.31": "Navarra",
    "town.724.32": "Ourense",
    "town.724.34": "Palencia",
    "town.724.35": "Palmas, Las",
    "town.724.36": "Pontevedra",
    "town.724.26": "Rioja, La",
    "town.724.37": "Salamanca",
    "town.724.38": "Santa Cruz de Tenerife",
    "town.724.40": "Segovia",
    "town.724.41": "Sevilla",
    "town.724.42": "Soria",
    "town.724.43": "Tarragona",
    "town.724.44": "Teruel",
    "town.724.45": "Toledo",
    "town.724.46": "Valencia/València",
    "town.724.47": "Valladolid",
    "town.724.49": "Zamora",
    "town.724.50": "Zaragoza",
    "town.724.51": "Ceuta",
    "town.724.52": "Melilla",
    "town.484.16": "Ciudad de México",
    "town.484.20": "Aguascalientes",
    "town.484.21": "Baja California",
    "town.484.23": "Baja California Sur",
    "town.484.24": "Campeche",
    "town.484.29": "Chiapas",
    "town.484.31": "Chihuahua",
    "town.484.25": "Coahuila",
    "town.484.28": "Colima",
    "town.484.34": "Durango",
    "town.484.36": "Guanajuato",
    "town.484.39": "Guerrero",
    "town.484.42": "Hidalgo",
    "town.484.44": "Jalisco",
    "town.484.50": "México",
    "town.484.58": "Michoacán",
    "town.484.62": "Morelos",
    "town.484.63": "Nayarit",
    "town.484.64": "Nuevo León",
    "town.484.68": "Oaxaca",
    "town.484.72": "Puebla",
    "town.484.76": "Querétaro",
    "town.484.77": "Quintana Roo",
    "town.484.78": "San Luis Potosí",
    "town.484.80": "Sinaloa",
    "town.484.83": "Sonora",
    "town.484.86": "Tabasco",
    "town.484.87": "Tamaulipas",
    "town.484.90": "Tlaxcala",
    "town.484.91": "Verazcruz",
    "town.484.97": "Yucatán",
    "town.484.98": "Zacatecas",
}
