import Vue from 'vue';
import AttendanceApi from "@/api/core/attendance";

const initialState = () => ({
    attendanceTypes: {},
    innactiveAttendanceTypes: {},
    askedAttendanceTypes: []
});
export const attendance = {
    state: initialState(),
    mutations: {
        setAttendanceTypes(state, payload) {
            let strStageId = String(payload.index)
            //console.log("attendance.js store setAttendanceTypes - strStageId:",strStageId," - value: ",payload.value," - ",(typeof payload.value))
            Vue.set(state.attendanceTypes, strStageId, payload.value)
        },
        setInnactiveAttendanceTypes(state, payload) {
            let strStageId = String(payload.index)
            //console.log("attendance.js store setAttendanceTypes - strStageId:",strStageId," - value: ",payload.value," - ",(typeof payload.value))
            Vue.set(state.innactiveAttendanceTypes, strStageId, payload.value)
        },
        resetCenterInfo(state) {
            state.attendanceTypes = {} //initialState()
            state.askedAttendanceTypes = []
            state.innactiveAttendanceTypes = {}
        },
        removeAttendanceTypeFromStage(state, payload){
            let stageId = String(payload.stageId);
            let attendanceTypeId = String(payload.attendanceTypeId);
            if('0' in state.attendanceTypes) {
                let all_ats = state.attendanceTypes[0]; // Haurem de buscar el k té id = attendanceTypeId i treure del properties el key stageId
                let allat_pos = all_ats.findIndex(ats => ats.id == attendanceTypeId);
                if(allat_pos>=0) {
                    delete all_ats[allat_pos]['properties'][stageId];
                    Vue.set(state.attendanceTypes, 0, all_ats);
                }
            }
            if(stageId in state.attendanceTypes) {
                let stage_ats = state.attendanceTypes[stageId];
                let innactive_ats = state.innactiveAttendanceTypes[stageId];
                let stageats_pos = stage_ats.findIndex(a => a.id == attendanceTypeId); // Busquem l'obj amb id=attendanceTypeId
                if(stageats_pos>=0) {
                    innactive_ats.push(stage_ats[stageats_pos]); // Afegir-lo als innactius
                    stage_ats.splice(stageats_pos,1);// Treure'l dels actius
                    Vue.set(state.attendanceTypes, stageId, stage_ats);
                    Vue.set(state.innactiveAttendanceTypes, stageId, innactive_ats);
                }
            }
            let a = Object.assign({}, state.attendanceTypes);
            state.attendanceTypes = a;
        },
        addAttendanceTypeFromStage(state, payload){
            let stageId = String(payload.stageId);
            let attendanceTypeId = String(payload.attendanceTypeId);
            let attendanceTypeStage = payload.attendanceTypeStage;
            if('0' in state.attendanceTypes) {
                let all_ats = state.attendanceTypes[0];
                let allat_pos = all_ats.findIndex(ats => ats.id == attendanceTypeId);
                if(allat_pos>=0) {
                    all_ats[allat_pos]['properties'][stageId] = attendanceTypeStage;
                    Vue.set(state.attendanceTypes, 0, all_ats);
                }

                if(stageId in state.attendanceTypes) {
                    let stage_ats = state.attendanceTypes[stageId];
                    stage_ats.splice(attendanceTypeStage.position, 0, all_ats[allat_pos]); // Afegir-lo als actius
                    Vue.set(state.attendanceTypes, stageId, stage_ats);
                    let innactive_ats = state.innactiveAttendanceTypes[stageId];
                    let istageats_pos = innactive_ats.findIndex(a => a.id == attendanceTypeId); // Busquem l'obj amb id=attendanceTypeId
                    innactive_ats.splice(istageats_pos,1); // Treure'l dels innactius
                    Vue.set(state.innactiveAttendanceTypes, stageId, innactive_ats);
                }
                let a = Object.assign({}, state.attendanceTypes);
                state.attendanceTypes = a;
            }
            //console.log("FI addAttendanceTypeFromStage");
        },
    },
    actions: {
        async getAttendanceTypes(context, params){
            let stageValue = params.stageValue;
            let joinStage = params.joinStage;
            let attendanceTypes = { 'index': stageValue, 'value': [] };
            if(context.state.askedAttendanceTypes.indexOf(stageValue) < 0) {
                //console.log("attendance.js store getAttendanceTypes IF stageValue: ",stageValue);
                context.state.askedAttendanceTypes.push(stageValue);
                await AttendanceApi.getAttendanceTypes(stageValue,joinStage).then((response) => {
                    if (Array.isArray(response.data.attendanceTypes)) {
                        attendanceTypes.value = response.data.attendanceTypes;
                    }
                    context.commit("setAttendanceTypes", attendanceTypes);
                }).catch(e => {
                    context.commit("setAttendanceTypes", attendanceTypes);
                });
            }else {
                //console.log("attendance.js store getAttendanceTypes ELSE stageValue:",stageValue," - ",context.state.attendanceTypes);
                return context.state.attendanceTypes[stageValue];
            }
        },
        async getInnactiveAttendanceTypes(context, params){
            //console.log("STORE getInnactiveAttendanceTypes ", context.state.attendanceTypes);
            let stageTypes = []; let generalTypes = []; let stageTypesIds = []; let generalTypesIds = [];
            await context.dispatch('getAttendanceTypes', {stageValue:0});
            await context.dispatch('getAttendanceTypes', params);
            var allTypes = context.state.attendanceTypes;
            //console.log("general: ", generalTypes, " - stageTypes: ",stageTypes);
            if('0' in allTypes) {
                generalTypes = allTypes[0];
                generalTypesIds = generalTypes.map(x => x.id);
            }
            if(params.stageValue in allTypes) {
                stageTypes = allTypes[params.stageValue];
                stageTypesIds = stageTypes.map(x => x.id);
            }
            //console.log("generalTypesIds: ", generalTypesIds);
            let innactiveIds = generalTypesIds.filter(x => !stageTypesIds.includes(x));
            let innactives = generalTypes.filter(x => innactiveIds.includes(x.id));
            // console.log("innactives: ", innactives);
            context.commit("setInnactiveAttendanceTypes", { 'index':params.stageValue, 'value': innactives });
        },
    },
    getters: {
        getAttendanceTypes: state => {
            return state.attendanceTypes
        },
        getInnactiveAttendanceTypes: state => {
            return state.innactiveAttendanceTypes
        },
    }
}
