import axios from 'axios';
import entityHelper from "@/api/entityHelper";

export default {
    getGradesPrograms(filters, page, pageSize, orderField, orderMode) {
        let params = {
            filters: filters,
            page: page,
            pageSize: pageSize,
            orderField: orderField,
            orderMode: orderMode
        };
        return axios.get('/grades', {params});
    },
    getEnrolmentsOfProgram(programId, groupId) {
        return axios.get('/enrolments/program/'+programId+'/group/'+groupId);
    },
    saveEvaluable(id, name, categoryType, evaluabletype, programId, groupId, subjectId, evaluableConvocatories, isFinal, formula, convocatoryId, oldCompetences, competences, isPublic, deliveryDate, createCalendar, notify, yearCode = null){
        let params = {
            id: id,
            name: name,
            categoryType: categoryType,
            evaluabletype: evaluabletype,
            programId: programId,
            groupId: groupId,
            subjectId: subjectId,
            evaluableConvocatories: evaluableConvocatories,
            isFinal: isFinal,
            formula: formula,
            convocatoryId: convocatoryId,
            oldCompetences: oldCompetences,
            competences: competences,
            isPublic: isPublic,
            deliveryDate: deliveryDate,
            createCalendar: createCalendar,
            notify: notify,
            yearCode: yearCode
        };
        return axios.post('/grades/saveEvaluable', {params});
    },
    getEvaluablesOfGroupProgram(programId, groupId, periodId, yearView, viewAll = false, filterYear= null){
        let params = {
            periodId: periodId,
            groupId: groupId,
            programId: programId,
            yearView: yearView,
            viewAll: viewAll //Ver todo sin tener en cuenta el periodId
        };
        if(filterYear && filterYear != undefined){
            params.year = filterYear;
        }
        return axios.get('/grades/getEvaluablesOfGroupProgram', {params});
    },
    getGradesOfEvaluablesForGroupProgramPeriod(programId, groupId, periodId, finalsOnly, filterYear=null){
        let params = {
            periodId: periodId,
            groupId: groupId,
            programId: programId,
            finalsOnly: finalsOnly,
        };
        if(filterYear && filterYear != undefined){
            params.year = filterYear;
        }
        // var today = new Date();
        // var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        // console.log("getGradesOfEvaluablesForGroupProgramPeriod", time);
        return axios.get('/grades/getGradesOfEvaluablesForGroupProgramPeriod', {params});
    },
    removeEvaluable(evaluableId, yearCode = null){
        let params = {
            year: yearCode
        }
        return axios.delete('/grades/evaluables/'+evaluableId, {params});
    },
    savePersonEvaluableGradeValue(gradeEvaluableId, personId, evaluableId, convocatoryId, value, gradeType, evaluabletype, isFinal, year = null){
        let params = {
            personId: personId,
            evaluableId: evaluableId,
            value: value,
            gradeType: gradeType,
            convocatoryId: convocatoryId,
            gradeEvaluableId: gradeEvaluableId,
            isFinal: isFinal,
            evaluabletype: evaluabletype,
            year: year
        };
        //console.log("savePersonEvaluableGradeValue ", params);
        return axios.post('/grades/savePersonEvaluableGradeValue', {params});
    },
    savePersonsEvaluableGradeValue(grades, evaluableId, convocatoryId, yearCode = null){
        let params = {
            grades: grades,
            evaluableId: evaluableId,
            convocatoryId: convocatoryId,
            year: yearCode
        };
        return axios.post('/grades/savePersonsEvaluableGradeValue', {params});
    },
    getEvaluationPeriodsByCourseStage(courseStageId, cancelToken, year = null) {
        let params = {parentsFirst: true};

        params['courseStageId'] = courseStageId;
        if (cancelToken) {
            params['cancelToken'] = cancelToken.token;
        }
        if(year){
            params['year'] = year;
        }

        return axios.get('/grades/convocatories/ofCourseStage', {params});
        // return axios.get('/grades/getEvaluationPeriodsByCourseStage', {params});
    },
    saveEvaluableType(id, name, isSystem, isNumeric, minValue, maxValue, evaluableTypeValues, categoryType){
        let params = {
            id: id,
            name: name,
            isSystem: isSystem,
            isNumeric: isNumeric,
            minValue: minValue,
            maxValue: maxValue,
            evaluableTypeValues: evaluableTypeValues,
            categoryType: categoryType,
        };

        return axios.post('/grades/saveEvaluableType', {params});
    },
    getAllConvocatories(filters){
        let params = {};
        if(filters){ params.filters = filters};
        return axios.get('/grades/convocatories/list', {params});
    },
    changePositions(positionsArray){
        let params = {'convocatoriesPositions': positionsArray};
        return axios.post('/grades/convocatories/changePositions', {params});
    },
    changeParents(parentId, convocatoriesIds){
        let params = {'parentId': parentId, 'convocatoriesIds': convocatoriesIds};
        return axios.post('/grades/convocatories/changeParents', {params});
    },
    saveConvocatory(id, code, name, courseStages, startDate, endDate, isActive, publics, position, parentId, isRecovery, year){
        if(id==null){ id=0; }
        let params = {
            id: id,
            code: code,
            name: name,
            courseStages: courseStages,
            startDate: startDate,
            endDate: endDate,
            isActive: isActive,
            public: publics,
            position: position,
            parentId: parentId,
            isRecovery: isRecovery,
        };
        if(year != undefined || year != null){
            params.year = year;
        }
        return axios.post('/grades/convocatory/' + id, {params});
    },
    findConvocatories(filters, currentPage, perPage, sortField, direction){
        let params = {
            filters: filters,
            currentPage: currentPage,
            perPage: perPage,
            sortField: sortField,
            direction: direction,
        };
        return axios.get('/grades/findConvocatories', {params});
    },
    /*saveConvocatory(id, name, courseStages){
        let params = {
            id: id,
            name: name,
            courseStages: courseStages
        };

        return axios.post('/grades/saveConvocatory', {params});
    },*/
    deleteConvocatory(id, force = false, yearCode = null){
        let params = { id: id, force: force, yearCode: yearCode};
        return axios.delete('/grades/convocatory/'+id, {params});
    },
    getGradesPDFByGroupAndConvocatory(groupId, convocatoryId, functionalityId, templateId, programId, year = null){
        let params = {
            groupId : groupId,
            convocatoryId: convocatoryId,
            functionalityId: functionalityId,
            templateId: templateId,
            programId: programId,
            year: year
        };
        return axios.get('/grades/getGradesPDFByGroup', {params: params, responseType: 'arraybuffer'});
    },
    getGradesPDFByPerson(personId, groupId, templateId, courseStageId = null, convocatoryId = null, programId = null){
        let params = {
            studentId : personId,
            groupId: groupId,
            templateId: templateId,
            courseStageId: courseStageId,
            convocatoryId: convocatoryId,
            programId: programId
        };
        return axios.get('/grades/getGradesPDFByStudent', {params: params, responseType: 'arraybuffer'});
    },
    getGradesPDFByEnrolmentAndConvocatory(enrolmentId, convocatoryId, functionalityId = null, templateId = null, programId = null, year = null){
        let params = {
            enrolmentId : enrolmentId,
            convocatoryId: convocatoryId,
            functionalityId: functionalityId,
            templateId: templateId,
            programId: programId,
            year: year
        };
        return axios.get('/grades/getGradesPDFByEnrolmentAndConvocatory', {params: params, responseType: 'arraybuffer'});
    },
    getGradesPDFByEnrolmentsAndConvocatory(enrolmentIds, convocatoryId, functionalityId = null, templateId = null){
        let params = {
            enrolmentIds : enrolmentIds,
            convocatoryId: convocatoryId,
            functionalityId: functionalityId,
            templateId: templateId,
        };
        return axios.get('/grades/getGradesPDFByEnrolmentsAndConvocatory', {params: params, responseType: 'arraybuffer'});
    },
    getPrintPDF(studentId, groupId, templateId, functionalityId, courseStageId = null, convocatoryId = null){
        let params = {
            studentId: studentId,
            groupId: groupId,
            templateId: templateId,
            functionalityId: functionalityId,
            courseStageId: courseStageId,
            convocatoryId: convocatoryId
        };
        //return axios.get('/grades/getStudentPDFByPeriodAndTemplate', {params: params, responseType: 'arraybuffer'});
        return axios.get('/grades/getGradesPDFByStudent', {params: params, responseType: 'arraybuffer'});
    },
    getStudentPDF(personId, courseStageId){
        let params = {personId : personId, courseStageId: courseStageId};
        return axios.get('/grades/getStudentPDF', {params: params, responseType: 'arraybuffer'});
    },
    getGradesOfStudent(personId, yearCode = null, familyView = 0, viewAll = true, enrolmentId = null){
        let params = {personId: personId, yearCode: yearCode, viewAll: viewAll, familyView: familyView , enrolmentId: enrolmentId};
        if(yearCode && yearCode!=undefined){
            params.year = yearCode;
        }
        return axios.get('/grades/getGradesOfStudent', {params});
    },
    getGradesInfo(groupCourseStageId, yearCode, convocatoryId) {
        //console.log("getGradesInfo");
        let params = {
            'groupCourseStageId': groupCourseStageId,
            'yearCode': yearCode,
            'convocatoryId': convocatoryId,
        };

        return axios.get('/grades/getGradesInfo/', { 'params': params });
    },
    removeGradeEvaluable(gradeEvaluableId, evaluableId){
        let params = {};
        if(gradeEvaluableId != null){
            params.id = gradeEvaluableId;
        }
        //return axios.post('/grades/gradeevaluable', params);
        return axios.delete('/grades/gradeevaluable/'+gradeEvaluableId);
    },

    removeGradeConvocatory(gradeEvaluableId, evaluableId){
        let params = {};
        if(gradeEvaluableId != null){
            params.id = gradeEvaluableId;
            return axios.delete('/grades/gradeconvocatory/'+gradeEvaluableId);
        }
    },
    saveCompetence(id, color, icon, code, name, dimension, ambitId, programId, yearCode){
        let params = {
            'id': id,
            'color': color,
            'icon': icon,
            'code': code,
            'name': name,
            'dimension': dimension,
            'ambitId': ambitId,
            'programId': programId,
            'year': yearCode
        };
        return axios.post('/grades/saveCompetence', {params})
    },
    saveCompetences(competences, year){
        let params = {
            'competences': competences,
        };
        if(year != undefined || year != null){
            params.year = year;
        }
        return axios.post('/grades/saveCompetences', {params})
    },
    getCompetences(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/grades/competences/list', filters, page, pageSize, orderField, orderMode);
    },
    getCompetencesGroupList(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/grades/competencesGroupList', filters, page, pageSize, orderField, orderMode);
    },
    getCompetencesDropdown(type, stage){

    },
    deleteCompetence(id){
        return axios.delete('/grades/deleteCompetence/'+id)
    },
    saveAmbit(id, code, name, responsibleId = null, year){
        let params = {
            id: id,
            code: code,
            name: name,
            responsibleId: responsibleId
        };
        if(year != undefined || year != null){
            params.year = year;
        }
        return axios.post('/grades/saveAmbit', {params})
    },
    getAmbits(filters, page, pageSize, orderField, orderMode) {
        return entityHelper.findEntity('/ambits/list', filters, page, pageSize, orderField, orderMode);
    },
    deleteAmbit(id){
        return axios.delete('/grades/deleteAmbit/'+id)
    },
    getAmbitsDropdown(){
        return axios.get('/grades/ambits/dropdown');
    },
    saveSubjectAmbit(ambitId, subjectId, percentage, position, programId = null){
        let params = {
            subjectId: subjectId,
            percentage: percentage,
            position: position,
            programId: programId
        };
        return axios.post('/ambits/'+ambitId+'/saveSubject', {params})
    },
    saveSubjectsAmbit(ambitId, subjectsId, percentage, position, programId = null){
        let params = {
            subjectsId: subjectsId,
            percentage: percentage,
            position: position,
            programId: programId
        };
        return axios.post('/ambits/'+ambitId+'/saveSubjects', {params})
    },
    deleteSubjectAmbit(ambitId, subjectId){
        return axios.delete('/ambits/'+ambitId+'/deleteSubject/'+subjectId)
    },
    saveSubjectsPositionsAmbit(id, subjectsPositions){
        let params = {
            subjectsPositions: subjectsPositions
        };
        return axios.post('/ambits/'+id+'/saveSubjectsPositions/', {params})
    },
    getCompetencesOfProgram(id, convocatoriesIds = [], filterYear){
        let params = {
            convocatoriesIds: convocatoriesIds
        };
        if(filterYear && filterYear != undefined && filterYear != null){
            params.year = filterYear;
        }
        return axios.get('/grades/getCompetencesOfProgram/'+id, {params});
    },
    getCompetencesProgram(id, convocatoryId = null, yearCode = null, schema = null){
        let params = {
            convocatoryId: convocatoryId,
            yearCode: yearCode,
        };

        if (schema != null) {
            params['schema'] = schema;
        }

        return axios.get('/grades/getCompetencesProgram/'+id, {params});
    },
    changeProgramCompetencePositions(positionsArray, yearCode){
        let params = {'programCompetencesPositions': positionsArray, yearCode: yearCode};
        return axios.post('/grades/changeProgramCompetencePositions', {params});
    },
    addCompetencesToProgram(idProgram, idsCompetences, year = null, convocatoryId = null){
        let params = {
            idProgram: idProgram,
            idsCompetences: idsCompetences,
        };
        if(year != undefined || year != null){
            params.year = year;
        }
        if(convocatoryId != undefined || convocatoryId != null){
            params.convocatoryId = convocatoryId;
        }
        return axios.post('/grades/addCompetencesToProgram', {params});
    },

    deleteCompetencesFromProgram(idProgram, idsCompetences){
        let params = {
            idProgram: idProgram,
            idsCompetences: idsCompetences,
        };
        return axios.delete('/grades/deleteCompetencesFromProgram/',{params});
    },

    saveDimensionAndCompetences(dimension, competences, toRemove, programId = null, yearCode = null){
        let params = {
            dimension: dimension,
            competences: competences,
            toRemove: toRemove,
            programId: programId,
            year: yearCode
        };
        return axios.post('/grades/saveDimensionAndCompetences', {params});
    },
    getEvaluabletypes(){
        return axios.get('/grades/evaluabletypes');
    },
    getAmbitOfProgram(programId, yearCode = null){
        let params = {
            year: yearCode
        };
        return axios.get('/grades/getAmbitOfProgram/'+programId, {params});
    },
    getGradesOfCompetencesForGroupProgramPeriod(programId, groupId, periodsIds = [], competencesIds = [], year = null){
        let params = {
            periodsIds: periodsIds,
            groupId: groupId,
            programId: programId,
            competencesIds: competencesIds,
            year: year
        };
        return axios.get('/grades/getGradesOfCompetencesForGroupProgramPeriod', {params});
    },
    savePersonCompetenceGradeValue(gradeCompetenceId, personId, competenceId, convocatoryId, value, programId, yearCode = null){
        let params = {
            personId: personId,
            competenceId: competenceId,
            value: value,
            convocatoryId: convocatoryId,
            gradeCompetenceId: gradeCompetenceId,
            programId: programId,
            yearCode: yearCode
        };
        return axios.post('/grades/savePersonCompetenceGradeValue', {params});
    },
    saveCommentGrades(personId, value, isPublic, programId, evaluableId, competenceId, convocatoryId, commentGradeId, categoryId, stageId, courseStageId, subjectId, commentDefaultId, year = null){
        let params = {
            personId: personId,
            value: value,
            isPublic: isPublic,
            programId: programId,
            evaluableId: evaluableId,
            competenceId: competenceId,
            convocatoryId: convocatoryId,
            commentGradeId: commentGradeId,
            categoryId: categoryId,
            stageId: stageId,
            courseStageId: courseStageId,
            subjectId: subjectId,
            commentDefaultId: commentDefaultId,
            year: year
        };
        return axios.post('/grades/comment', params);
    },
    getCommentsGrades(filters, sortField, sortOrder){
        let params = {
            filters: filters,
            sortField: sortField,
            sortOrder: sortOrder
        };
        return axios.get('/grades/comment', {params});
    },
    addEvaluableToEvaluationPeriod(evaluableId, convocatoryId, yearCode = null){
        return axios.post('/grades/addEvaluableToConvocatory', {
            evaluableId: evaluableId,
            convocatoryId: convocatoryId,
            year: yearCode
        });
    },
    removeEvaluableFromEvaluationPeriod(evaluableId, convocatoryId, yearCode){
        return axios.post('/grades/removeEvaluableFromConvocatory', {
            evaluableId: evaluableId,
            convocatoryId: convocatoryId,
            year: yearCode
        });
    },
    changeEvaluablesPositions(convocatoryId, evaluableIds, yearCode = null){
        return axios.post('/grades/changeEvaluablesPositions', {
            convocatoryId: convocatoryId,
            evaluableIds: evaluableIds,
            year: yearCode
        });
    },
    setEvaluablesVisible(evaluables, yearCode = null){
        /**
         * { evaluableId: show, evaluableId2: show2... }
         */
        return axios.post('/grades/evaluablesVisible', {
            evaluables: evaluables,
            year: yearCode
        });
    },
    getPublicGradesOfStudentBySubject(studentId, subjectId){
        let params = {
            studentId: studentId,
            subjectId: subjectId
        };
        return axios.get('/grades/getPublicGradesOfStudentBySubject', {params});
    },
    removeCommentGrades(commentId, year){
        let params = {
            year: year
        }
        return axios.delete('grades/comment/'+commentId, {params});
    },
    generateReportCard(studentId, groupId, programId, convocatoryId, functionality) {
        let params = {
            'studentId': studentId,
            'groupId': groupId,
            'programId': programId,
            'convocatoryId': convocatoryId,
            'functionality': functionality,
        };

        return axios.get('/grades/report-card', { 'params': params });
    },
    getProgramsOfCompetence(id) {
        return axios.get('/grades/getProgramsOfCompetence/'+id);
    },
    getGradesOfProgramsForCompetencePeriod(competenceId, courseStageId, periodId){
        let params = {
            periodId: periodId,
            courseStageId: courseStageId,
            competenceId: competenceId,
        };
        return axios.get('/grades/getGradesOfProgramsForCompetencePeriod', {params});
    },
    getConvocatoriesGroupedByCourseStage(year = null){
        let params = { };
        if(year != null){
            params.year = year;
        }
        return axios.get('/grades/convocatories/groupedByCourseStage', {params});
    },
    saveConvocatoriesCourseStage(convocatoriesIds, courseStages, year){
        let params = {
            "convocatoriesIds": convocatoriesIds,
            "courseStages": courseStages,
            "year": year
        };
        // convocatoriesIds = [1, 2, convocatoryId...],
        // courseStages = {3: true, 4: true, 7: false, courseStageId: true/false...}
        return axios.post('/grades/convocatories/manageByCourseStage', {params});
    },

    /** PREDEFINED COMMENTS */
    getPredefinedCommentCategories() {
        return axios.get('/grades/commentGradesCategories');
    },
    savePredefinedCommentCategory(commentCategory) {
        return axios.post('/grades/commentCategory', {id: commentCategory.id, value: commentCategory.name});
    },
    deletePredefinedCommentCategory(commentCategory) {
        return axios.delete('/grades/commentCategory/' + commentCategory.id);
    },
    getPredefinedCommentsAdmin(filters, page, pageSize, orderField, orderMode, format = null) {
        return axios.get('/comment-default-config', {params: {filters: filters, page: page, perPage: pageSize, sort: orderField + '|' + orderMode, format: format}});
    },
    getPredefinedComments(filters, page, pageSize, orderField, orderMode) {
        return axios.get('/comment-default', {params: {filters: filters, page: page, perPage: pageSize, sort: orderField + '|' + orderMode}});
    },
    // ARREGLAR RUTAS
    setPredefinedCommentsAttributes(commentsIds, attributesValues){ //ej: setCommentsAttributes([3,4],['isActive'=>1, 'competenceId'=>23])
        return axios.post('/grades/commentsAttributes', {commentsIds, attributesValues});
    },
    deletePredefinedCommentsAttributes(commentsIds, attribute){
        return axios.post('/grades/commentsDeleteAttribute', {commentsIds, attribute});
    },

    // FIN ARREGLAR RUTAS
    /**
     * Guardar comentari predefinit
     * @param {Object} predefinedComment value, code, isPublic, personId, categoryId, stageId, courseStageId, subjectId, competenceId, commentGradeId
     * @returns
     */
    savePredefinedCommentAdmin(predefinedComment) {
        return axios.post("/comment-default-config", predefinedComment);
    },
    /**
     * Guardar comentari predefinit
     * @param {Object} predefinedComment value, code, isPublic, personId, categoryId, stageId, courseStageId, subjectId, competenceId, commentGradeId
     * @returns
     */
    savePredefinedComment(predefinedComment) {
        return axios.post("/comment-default", predefinedComment);
    },
    deletePredefinedCommentAdmin(comment) {
        return axios.delete('/comment-default-config/' + comment.id);
    },
    deletePredefinedComment(comment) {
        return axios.delete('/comment-default/' + comment.id);
    },
    /** END PREDEFINED COMMENTS */

    getEvaluablesCompetences(convocatoryId = null, programId = null) {
        let params = {};
        if(programId != null){
            params.programId = programId;
        }
        if(convocatoryId != null){
            params.convocatoryId = convocatoryId;
        }
        return axios.get("/grades/evaluable-competences", {params});
    },
    getDefaultEvaluableType(){
        return axios.get("/grades/default-evaluable-type");
    },
    getCompetencesOfClasses(courseStageId = null, transversals = null, teacherId = false, filteredYear = null){
        let params = {};
        if(courseStageId != null){
            params.courseStageId = courseStageId;
        }
        if(transversals != null){
            params.transversals = transversals;
        }
        if(teacherId != null){
            params.teacherId = teacherId;
        }
        if(filteredYear != null){
            params.year = filteredYear;
        }
        return axios.get("/grades/competences-of-classes", {params});
    },
    generatePDFForGroupsInOrder(orderedGroups, convocatoryId, templateId, functionalityId, documentType, year){
        let params = {
            orderedGroups: orderedGroups,
            templateId: templateId,
            convocatoryId: convocatoryId,
            functionalityId: functionalityId,
            documentType: documentType,
            year: year
        };
        return axios.get("/grades/generatePDFForGroupsInOrder", {params, responseType: documentType =='PDF' ? 'arraybuffer' : 'json'});
    },
    findEvaluationPeriods(filters, currentPage, perPage, sortField, direction){
        let params = {
            filters: filters,
            currentPage: currentPage,
            perPage: perPage,
            sortField: sortField,
            direction: direction,
        };
        return axios.get('/grades/convocatories/list', {params});
    },
    sendGradesReportStudent(enrolmentId, convocatoryId, templateId, functionalityId, programId = null, year = null) {
        let params = {
            enrolmentId : enrolmentId,
            convocatoryId: convocatoryId,
            templateId: templateId,
            functionalityId: functionalityId,
            programId: programId,
            year: year,
        };

        return axios.get('grades/sendGradesStudent', { 'params': params });
    },
    sendGradesReportsGroup(groupId, convocatoryId, templateId, functionalityId, programId = null, year = null) {
        let params = {
            groupId : groupId,
            convocatoryId: convocatoryId,
            templateId: templateId,
            functionalityId: functionalityId,
            programId: programId,
            year: year,
        };

        return axios.get('/grades/sendGradesGroup', { 'params': params });
    },
    sendGradesGroupHasContacts(groupId, convocatoryId, templateId, functionalityId, programId = null, year = null) {
        let params = {
            groupId : groupId,
            convocatoryId: convocatoryId,
            templateId: templateId,
            functionalityId: functionalityId,
            programId: programId,
            year: year
        };

        return axios.get('/grades/sendGradesGroupHasContacts', { 'params': params });
    },
    assignDefaultCommentsByGrades(stageId, convocatoryId, yearCode){
        return axios.post('/grades/assignDefaultCommentsByGrades', {stageId: stageId, convocatoryId: convocatoryId, year: yearCode});
    },
    saveAverageValuesToConvocatory(grades, convocatoryId, programId, groupId){
        return axios.post('/grades/assignAverageGrades', {grades: grades, programId: programId, groupId: groupId, convocatoryId: convocatoryId});
    },
    activateConvocatory(convocatoryId, yearCode){
        return axios.post('/grades/convocatory/'+convocatoryId+'/activate', {convocatoryId: convocatoryId, year: yearCode});
    },
    disableConvocatory(convocatoryId, yearCode){
        return axios.post('/grades/convocatory/'+convocatoryId+'/disable', {convocatoryId: convocatoryId, year: yearCode});
    },
    addFinalGradesFromStage(stageId){
        return axios.post('/grades/addFinalGradesFromStage', {stageId: stageId});
    }
}
