import entityHelper from '@/api/entityHelper';
import axios from 'axios';
import image from '../../utils/image';

//al profile i la persona van a controllers diferents ja que els permisos són diferents
// no es el mateix gestionar-te a tu mateix (password, tags...) que gestionar a una persona

export default {
    centerImage(centerCode, urlImg){
        if(centerCode != "" && (typeof centerCode != "undefined") && centerCode != null) {
            return axios.get('/centerImage/'+centerCode+'/'+urlImg,{responseType: 'arraybuffer'}).then(
                (response) => {
                    if(response.data!="" && response.data!="undefined" && response.data.byteLength>0){
                        var bytes = new Uint8Array(response.data); // get info de la imatge que hem serveix el backend
                        var binary = bytes.reduce((data, b) => data += String.fromCharCode(b), ''); // bytes latin
                        return "data:image/jpeg;base64," + btoa(binary); // base62
                    }
                }
            );
        }

        return new Promise((resolve, reject) => { resolve(""); });
    },
    getTimeZones() {
        return axios.get('/center/timezone').then((response) => {
            if (response) {
                return response.data
            }
        });
    },
    getLanguages() {
        return axios.get('/center/language').then((response) => {
            if (response) {
                return response.data
            }
        });
    },
    getScheduleSoftwares() {
        return axios.get('/center/schedule-soft').then((response) => {
            if (response) {
                return response.data
            }
        });
    },
    getExternalSoftwares() {
        return axios.get('/center/external-soft').then((response) => {
            if (response) {
                return response.data
            }
        });
    },
    getCurrentCenter() {
        return axios.get('/centerprofile').then((response) => {
            if (response) {
                return response.data;
            }
        });
    },
    getCenters() {
        return axios.get('/center').then((response) => {
            if (response) {
                return response.data;
            }
            return [];
        }).catch((error) => {
            console.log("ERROR en el getCenters --> "+error)
        });
    },
    getCenterLanguage(){
        return axios.get("/centerLanguage").then((response) =>{
            if (response){
                return response.data;
            }
        })
    },
    saveCenter(center) {
        let centerProcessed = {};

        Object.assign(centerProcessed, center);

        if (centerProcessed.province !== null && typeof centerProcessed.province === 'object') {
            centerProcessed.province = centerProcessed.province.label;
        }

        if (centerProcessed.town !== null && typeof centerProcessed.town === 'object') {
            centerProcessed.town = centerProcessed.town.label;
        }

        if (centerProcessed.timeZone !== null && typeof centerProcessed.timeZone === 'object') {
            centerProcessed.timeZone = centerProcessed.timeZone.value;
        }

        if (centerProcessed.scheduleSoft !== null && typeof centerProcessed.scheduleSoft === 'object') {
            centerProcessed.scheduleSoft = centerProcessed.scheduleSoft.value;
        }

        if (centerProcessed.externalSoft !== null && typeof centerProcessed.externalSoft === 'object') {
            centerProcessed.externalSoft = centerProcessed.externalSoft.value;
        }

        return entityHelper.saveEntity(centerProcessed, '/center');
    },
    changeLanguage(id, newLanguage) {
        return axios.post('/language-center', {
            'id': id,
            'language': newLanguage
        }).then((response) => {
            if (response) {
                return response.data;
            }
        });
    },
    uploadLogo(centerCode, dataUrl, id) {
        let file = null;
        let config = {};
        let data = new FormData();

        // Convert dataUrl to File.
        if(dataUrl != null){
            file = image.dataUrlToFile(dataUrl, 'imatge.png');
        }

        config = {
            headers : { 'Content-Type': 'multipart/form-data' }
        };

        data.append('center_code', centerCode);
        data.append('logo_image', file);
        data.append('id', id);

        return axios.post('/upload-center-logo', data, config).then((response) => {
            if (response) {
                return response.data;
            }
        });
    },
    deleteLogo(centerCode, id) {
        // let data = new FormData();
        // data.append('center_code', centerCode);
        return axios.delete(`/center-logo/${id}?center_code=${centerCode}`).then(response => {
            if(response) {
                return response.data
            }
        })
    },
    getCenterYear(){
        return axios.get('/centerYear');
    },

    createCenter(centerCode, centerName, yearCode){
        let params = {'centerCode': centerCode, 'centerName': centerName, 'yearCode': yearCode};
        return axios.post('/center/create', {params});
    },

    changeTemplate(id, newTemplate) {
        return axios.post('/template-center', {
            'id': id,
            'template': newTemplate
        }).then((response) => {
            if (response) {
                return response.data;
            }
        });
    },

    getCenterTemplate(id){
        return axios.get('/center/template').then((response) => {
            if (response) {
                return response.data;
            }
        });
    }

}
