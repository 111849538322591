export default{
    "country.004": "Afganistan",
    "country.248": "Åland, illes; Aland, illes",
    "country.008": "Albània",
    "country.276": "Alemanya",
    "country.012": "Algèria",
    "country.020": "Andorra",
    "country.024": "Angola",
    "country.660": "Anguilla",
    "country.010": "Antàrtida",
    "country.028": "Antigua i Barbuda",
    "country.682": "Aràbia Saudita",
    "country.032": "Argentina",
    "country.051": "Armènia",
    "country.533": "Aruba",
    "country.036": "Austràlia",
    "country.040": "Àustria",
    "country.031": "Azerbaidjan; Azerbaidjan",
    "country.044": "Bahames",
    "country.048": "Bahrain",
    "country.050": "Bangla Desh; Bangladesh",
    "country.052": "Barbados",
    "country.056": "Bèlgica",
    "country.084": "Belize",
    "country.204": "Benín",
    "country.060": "Bermudes; Bermuda; Bermudes, les",
    "country.064": "Bhutan",
    "country.112": "Bielorússia",
    "country.068": "Bolívia",
    "country.535": "Bonaire, Sint Eustatius i Saba; Carib Neerlandès; Illes BES",
    "country.070": "Bòsnia i Hercegovina",
    "country.072": "Botswana",
    "country.074": "Bouvet; Bouvet, illa",
    "country.076": "Brasil",
    "country.096": "Brunei",
    "country.100": "Bulgària",
    "country.854": "Burkina Faso",
    "country.108": "Burundi",
    "country.136": "Caiman, illes; Caiman, les",
    "country.116": "Cambodja; Cambotja",
    "country.120": "Camerun",
    "country.124": "Canadà",
    "country.132": "Cap Verd",
    "country.140": "Centreafricana, República; Centrafricana, República",
    "country.162": "Christmas, illa",
    "country.166": "Cocos, illes; Cocos (Keeling), illes",
    "country.170": "Colòmbia",
    "country.174": "Comores",
    "country.178": "Congo, República del",
    "country.180": "Congo, República Democràtica del",
    "country.184": "Cook, illes",
    "country.408": "Corea del Nord; Corea, República Democràtica Popular de",
    "country.410": "Corea del Sud; Corea, República de",
    "country.384": "Costa d'Ivori",
    "country.188": "Costa Rica",
    "country.191": "Croàcia",
    "country.192": "Cuba",
    "country.531": "Curaçao",
    "country.208": "Dinamarca",
    "country.262": "Djibouti",
    "country.212": "Dominica",
    "country.214": "Dominicana, República",
    "country.818": "Egipte",
    "country.218": "Equador",
    "country.784": "Emirats Àrabs Units; Unió dels Emirats Àrabs",
    "country.232": "Eritrea",
    "country.703": "Eslovàquia",
    "country.705": "Eslovènia",
    "country.724": "Espanya",
    "country.840": "Estats Units (EUA); Estats Units d'Amèrica",
    "country.233": "Estònia",
    "country.231": "Etiòpia",
    "country.234": "Fèroe, illes",
    "country.242": "Fiji",
    "country.608": "Filipines",
    "country.246": "Finlàndia",
    "country.250": "França",
    "country.266": "Gabon",
    "country.270": "Gàmbia",
    "country.268": "Geòrgia",
    "country.239": "Geòrgia del Sud i Sandwich del Sud, illes",
    "country.288": "Ghana",
    "country.292": "Gibraltar",
    "country.300": "Grècia",
    "country.308": "Grenada",
    "country.304": "Groenlàndia",
    "country.312": "Guadeloupe; Guadalupe",
    "country.254": "Guaiana Francesa; Guaiana Francesa, la",
    "country.316": "Guam",
    "country.320": "Guatemala",
    "country.831": "Guernsey",
    "country.324": "República de Guinea",
    "country.624": "Guinea Bissau; Guinea-Bissau",
    "country.226": "Guinea Equatorial",
    "country.328": "Guyana",
    "country.332": "Haití",
    "country.334": "Heard, illa i McDonald, illes",
    "country.340": "Hondures",
    "country.344": "Hong Kong",
    "country.348": "Hongria",
    "country.887": "Iemen",
    "country.833": "Illa de Man",
    "country.581": "Illes Perifèriques Menors dels EUA; Estats Units d'Amèrica (illes menors allunyades)",
    "country.356": "Índia",
    "country.360": "Indonèsia",
    "country.364": "Iran",
    "country.368": "Iraq",
    "country.372": "Irlanda",
    "country.352": "Islàndia",
    "country.376": "Israel",
    "country.380": "Itàlia",
    "country.388": "Jamaica",
    "country.392": "Japó",
    "country.832": "Jersey",
    "country.400": "Jordània",
    "country.398": "Kazakhstan",
    "country.404": "Kenya",
    "country.417": "Kirguizistan",
    "country.296": "Kiribati",
    "country.414": "Kuwait",
    "country.418": "Laos",
    "country.426": "Lesotho",
    "country.428": "Letònia",
    "country.422": "Líban",
    "country.430": "Libèria",
    "country.434": "Líbia",
    "country.438": "Liechtenstein",
    "country.440": "Lituània",
    "country.442": "Luxemburg",
    "country.446": "Macau",
    "country.807": "Macedònia",
    "country.450": "Madagascar",
    "country.458": "Malàisia",
    "country.454": "Malawi",
    "country.462": "Maldives",
    "country.466": "Mali",
    "country.470": "Malta",
    "country.238": "Malvines, illes; Malvines (Falkland), illes",
    "country.580": "Mariannes Septentrionals, illes; Mariannes del Nord, illes; Marianes del Nord, illes",
    "country.504": "Marroc",
    "country.584": "Marshall, illes; Marshall",
    "country.474": "Martinica",
    "country.480": "Maurici",
    "country.478": "Mauritània",
    "country.175": "Mayotte",
    "country.484": "Mèxic",
    "country.583": "Micronèsia, Estats Federats de",
    "country.508": "Moçambic",
    "country.498": "Moldàvia",
    "country.492": "Mònaco",
    "country.496": "Mongòlia",
    "country.499": "Montenegro",
    "country.500": "Montserrat",
    "country.104": "Myanmar; Myanma",
    "country.516": "Namíbia",
    "country.520": "Nauru",
    "country.524": "Nepal",
    "country.558": "Nicaragua",
    "country.562": "Níger",
    "country.566": "Nigèria",
    "country.570": "Niue",
    "country.574": "Norfolk, illa; Norfolk",
    "country.578": "Noruega",
    "country.540": "Nova Caledònia",
    "country.554": "Nova Zelanda",
    "country.512": "Oman",
    "country.528": "Països Baixos",
    "country.586": "Pakistan",
    "country.585": "Palau",
    "country.275": "Palestina; Cisjordània i Gaza",
    "country.591": "Panamà",
    "country.598": "Papua Nova Guinea",
    "country.600": "Paraguai",
    "country.604": "Perú",
    "country.612": "Pitcairn, illes; Pitcairn",
    "country.258": "Polinèsia Francesa",
    "country.616": "Polònia",
    "country.620": "Portugal",
    "country.630": "Puerto Rico",
    "country.634": "Qatar",
    "country.826": "Regne Unit; Gran Bretanya",
    "country.638": "Reunió, illa de la; Reunió, la; Reunion; Réunion",
    "country.642": "Romania",
    "country.643": "Rússia",
    "country.646": "Ruanda",
    "country.732": "Sàhara Occidental",
    "country.659": "Saint Kitts i Nevis; Saint Christopher i Nevis",
    "country.662": "Saint Lucia",
    "country.666": "Saint-Pierre i Miquelon; Saint Pierre i Miquelon; Saint-Pierre-et-Miquelon",
    "country.670": "Saint Vincent i les Grenadines; Saint Vincent i Grenadines",
    "country.652": "Saint-Barthélemy; Saint-Barthélemy (Antilles)",
    "country.663": "Saint-Martin; Saint-Martin (Antilles Franceses)",
    "country.090": "Salomó",
    "country.222": "Salvador, El; Salvador, el",
    "country.882": "Samoa",
    "country.016": "Samoa Nord-americana; Samoa Americana",
    "country.674": "San Marino",
    "country.654": "Santa Helena; Saint Helena",
    "country.678": "São Tomé i Príncipe; Sao Tomé i Príncipe",
    "country.686": "Senegal",
    "country.688": "Sèrbia",
    "country.690": "Seychelles",
    "country.694": "Sierra Leone",
    "country.702": "Singapur",
    "country.534": "Sint Maarten",
    "country.760": "Síria",
    "country.706": "Somàlia",
    "country.144": "Sri Lanka",
    "country.710": "Sud-àfrica; Sud-àfrica, República de",
    "country.736": "Sudan",
    "country.728": "Sudan del Sud; República del Sudan del Sud",
    "country.752": "Suècia",
    "country.756": "Suïssa",
    "country.740": "Surinam",
    "country.744": "Svalbard i Jan Mayen",
    "country.748": "Swazilàndia",
    "country.762": "Tadjikistan",
    "country.764": "Tailàndia",
    "country.158": "Taiwan",
    "country.834": "Tanzània",
    "country.086": "Territori Britànic de l'Oceà Índic",
    "country.260": "Territoris Francesos del Sud; Terres Australs Franceses; França (territoris del sud)",
    "country.626": "Timor Oriental; Timor-Leste",
    "country.768": "Togo",
    "country.772": "Tokelau",
    "country.776": "Tonga",
    "country.780": "Trinitat i Tobago",
    "country.788": "Tunísia",
    "country.795": "Turkmenistan",
    "country.796": "Turks i Caicos, illes",
    "country.792": "Turquia",
    "country.798": "Tuvalu",
    "country.148": "Txad",
    "country.203": "Txèquia",
    "country.804": "Ucraïna",
    "country.800": "Uganda",
    "country.858": "Uruguai",
    "country.860": "Uzbekistan",
    "country.548": "Vanuatu",
    "country.336": "Vaticà, Ciutat del",
    "country.862": "Veneçuela",
    "country.092": "Verges Britàniques, illes; Verges, illes (Regne Unit)",
    "country.850": "Verges Nord-americanes, illes; Verges Americanes, illes; Verges, illes (Estats Units d'Amèrica)",
    "country.704": "Vietnam",
    "country.876": "Wallis i Futuna",
    "country.152": "Xile",
    "country.156": "Xina",
    "country.196": "Xipre",
    "country.894": "Zàmbia",
    "country.716": "Zimbabwe",
    "town.724.02": "Albacete",
    "town.724.03": "Alacant",
    "town.724.04": "Almeria",
    "town.724.01": "Àlava",
    "town.724.33": "Astúries",
    "town.724.05": "Àvila",
    "town.724.06": "Badajoz",
    "town.724.07": "Illes Balears",
    "town.724.08": "Barcelona",
    "town.724.48": "Biscaia",
    "town.724.09": "Burgos",
    "town.724.10": "Càceres",
    "town.724.11": "Cadis",
    "town.724.39": "Cantabria",
    "town.724.12": "Castelló",
    "town.724.13": "Ciudad Real",
    "town.724.14": "Còrdova",
    "town.724.15": "La Corunya",
    "town.724.16": "Conca",
    "town.724.20": "Guipúscoa",
    "town.724.17": "Girona",
    "town.724.18": "Granada",
    "town.724.19": "Guadalajara",
    "town.724.21": "Huelva",
    "town.724.22": "Huesca",
    "town.724.23": "Jaén",
    "town.724.24": "León",
    "town.724.25": "Lleida",
    "town.724.27": "Lugo",
    "town.724.28": "Madrid",
    "town.724.29": "Málaga",
    "town.724.30": "Murcia",
    "town.724.31": "Navarra",
    "town.724.32": "Ourense",
    "town.724.34": "Palencia",
    "town.724.35": "Palmas, Las",
    "town.724.36": "Pontevedra",
    "town.724.26": "Rioja, La",
    "town.724.37": "Salamanca",
    "town.724.38": "Santa Cruz de Tenerife",
    "town.724.40": "Segovia",
    "town.724.41": "Sevilla",
    "town.724.42": "Soria",
    "town.724.43": "Tarragona",
    "town.724.44": "Teruel",
    "town.724.45": "Toledo",
    "town.724.46": "Valencia/València",
    "town.724.47": "Valladolid",
    "town.724.49": "Zamora",
    "town.724.50": "Zaragoza",
    "town.724.51": "Ceuta",
    "town.724.52": "Melilla",
}
