import Vue from 'vue';
import $ from 'jquery';

export const Resizeable = {
  bind: function (el, binding) {
    const topMenu = 70;
    const breadCrumb = 75;
    const margin = 15;
    $(window).resize(function () {
      let offset = topMenu + breadCrumb + margin;
      if (binding.value != undefined) {
        offset += binding.value;
      }

      let size = window.innerHeight - offset;
      el.style.height = size + 'px';
    });

    let offset = topMenu + breadCrumb + margin;
    if (binding.value != undefined) {
      offset += binding.value;
    }

    let size = window.innerHeight - offset;
    el.style.height = size + 'px';

  },

};
