import Vue from 'vue'
import Vuex from 'vuex'
import ParameterApi from '@/api/core/parameters';

Vue.use(Vuex)
const initialState = () => ({
    parametersStudies: {
        basic: false,
        academic: false,
        formative: false,
    },
    parameterEmailMandatory: null,
    parameterMaximumGradeToEditRecovery: null,
    parameterOrderNamesTokapp: null,
    parameterAmbitOrAreaName: null,
});

export const parameters = {
    state: initialState(),
    mutations: {
        setParametersStudies(state, parameters){
            parameters.forEach(function (parameter) {
                state.parametersStudies[parameter.name] = parameter.value;
            })
        },
        setParameterEmailMandatory(state, value){
            state.parameterEmailMandatory = value;
        },
        setParameterOrderNamesTokapp(state, value){
            state.parameterOrderNamesTokapp = value;
        },
        setParameterAmbitOrAreaName(state, value){
            state.parameterAmbitOrAreaName = value;
        },
    },
    getters: {
        getParametersStudies: state => {
            return state.parametersStudies;
        },
        getParameterEmailMandatory: state => {
            return state.parameterEmailMandatory;
        },
        getParameterMaximumGradeToEditRecovery: state => {
            return state.parameterMaximumGradeToEditRecovery;
        },
        getParameterOrderNamesTokapp: state => {
            return state.parameterOrderNamesTokapp;
        },
        getParameterAmbitOrAreaName: state => {
            return state.parameterAmbitOrAreaName;
        }
    },
    actions: {
        async getParametersStudies({state, commit}){
            if(!state.parametersStudies.basic && !state.parametersStudies.academic && !state.parametersStudies.formative)
            await ParameterApi.getParameterValuesStage(['academyCycle', 'basicCycle', 'formativeCycle'], null).then(response => { //formativeCycle
                if(response){
                    let apiResponse = response.data.value;
                    state.parametersStudies.basic = apiResponse.filter(pv => pv.keyName === 'param.basicCycle').map(v => v.value === "1" || v.value === "true")[0];
                    state.parametersStudies.academic = apiResponse.filter(pv => pv.keyName === 'param.academyCycle').map(v => v.value === "1" || v.value === "true")[0];
                    state.parametersStudies.formative = apiResponse.filter(pv => pv.keyName === 'param.formativeCycle').map(v => v.value === "1" || v.value === "true")[0];
                }
            });
        },
        async getParameterEmailMandatory({state, commit}){
            if(state.parameterEmailMandatory === null){
                await ParameterApi.getParameterValuesStage(['emailMandatory'], null).then(response => { //formativeCycle
                    let apiResponse = response.data.value;
                    state.parameterEmailMandatory = apiResponse.filter(pv => pv.keyName === 'param.emailMandatory').map(v => v.value === "1" || v.value === "true")[0];
                });
            }

        },
        async getParameterMaximumGradeToEditRecovery({state, commit}){
            if(state.parameterMaximumGradeToEditRecovery){
                return state.parameterMaximumGradeToEditRecovery;
            }
            else if(state.parameterMaximumGradeToEditRecovery !== "pending"){
                state.parameterMaximumGradeToEditRecovery = "pending";
                await ParameterApi.getParameterValuesStage(['maximumGradeToEditRecovery'], null).then(response => { //formativeCycle
                    let apiResponse = response.data.value;
                    if(apiResponse !== null) state.parameterMaximumGradeToEditRecovery = apiResponse.filter(pv => pv.keyName === 'param.maximumGradeToEditRecovery').map(v => v.value)[0];
                });
            }
        },
        async getParameterOrderNamesTokapp({state, commit}){
            if(state.parameterOrderNamesTokapp === null){
                await ParameterApi.getParameterValuesStage(['orderNamesTokapp'], null).then(response => {
                    let apiResponse = response.data.value;
                    state.parameterOrderNamesTokapp = apiResponse.filter(pv => pv.keyName === 'param.orderNamesTokapp').map(v => v.value)[0];
                });
            }
        },
        async getParameterAmbitOrAreaName({state, commit}){
            if(state.parameterAmbitOrAreaName === null){
                await ParameterApi.getParameterValuesStage(['ambitOrAreaName'], null).then(response => {
                    let apiResponse = response.data.value;
                    state.parameterAmbitOrAreaName = apiResponse.filter(pv => pv.keyName === 'param.ambitOrAreaName').map(v => v.value)[0];
                });
            }
        },
    },
}
