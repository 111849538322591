import axios from 'axios';
import entityHelper from '@/api/entityHelper'

export default {
    getCurrentUser(){
      return axios.get('/user');
    },
    getUser(personId) {
        axios.get('/user/'+personId).then((response) => {
            //console.log(response);
            return response.data;
        });
    },
    searchUsers(filters, page, pageSize, sortField, sortOrder) {
        return entityHelper.findEntity("/users", filters, page, pageSize, sortField, sortOrder);
    },
    searchTeachers(filters, sortField, sortOrder) {
        return entityHelper.findEntity("/teachers", filters, null, null, sortField, sortOrder);
    },
    getPermissions(refresh = false) {
        let params = {refresh: refresh};
        return axios.get('userPermissions', {params});
    },
    saveUser(personId, user) {
        // console.log("---> user: ",user);
        return entityHelper.saveEntity(user, '/user/'+personId);//'+person.id);
    },
    deleteUser(personId) {
        if(personId !=undefined){
            return axios.delete('user/'+personId);
        }
    },
    getLang() {
      return axios.get('/userLang');
    },
    getUserHome(showSharedFolders = false) {
        return axios.get('/user-home', { params: { 'showSharedFolders': showSharedFolders } });
    },
    getUserToken(personId) {
        return axios.get('/user/'+personId+'/userToken');
    },
    changeUserAccess(personId, hasAccess){
        let parameters = {hasAccess: hasAccess};
        return axios.post('/users/'+personId+'/changeUserAccess', parameters);
    },
    changeOwnPassword(password){
        let params = {password: password}
        return axios.post("/changeOwnPassword", {params})
    },
}
