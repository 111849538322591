<template>
    <div class="left-sidebar">

            <nav class="sidebar-nav" id="menuMain">
                <div class="fix-element-menu align-middle"  @click="closeNav()">
                    <router-link :to="{ name: 'root'}" class="homeLink vertical-center-inside">
                        <span class="icon-fix-menu icon-house-alt" @click="closeMenu"></span>
                        <span class="span-text" @click="closeMenu">{{$t('home')}}</span>
                    </router-link>
                </div>
                <div class="ieduca-scrollbar-wrapper">
                    <div class="ieduca-scrollbar-general">
                        <left-menu-group :menu-items="menu"></left-menu-group>
                    </div>
                </div>
            </nav>

            <nav class="sidebar-nav" id="menuConfig" v-show="canViewConfigMenu">
                <div class="config-button vertical-center-inside" @click="open()">
                      <span class="icon-fix-menu icon-cog"></span>
                      <i name="min" class="min" :class="arrowClass" ></i>
                      <span  class="span-text">{{$t('settings')}}</span>
                      <i id="settings-arrow" name="big" class="arrow-down" :class="arrowClass"></i>
                </div>
                <div class="ieduca-scrollbar-wrapper">
                    <div class="ieduca-scrollbar-config">
                        <left-menu-group :menu-items="menuConfig"></left-menu-group>
                    </div>
                </div>
            </nav>

    </div>
</template>

<script>
    import $ from 'jquery';
    import VueScrollbar from 'vue2-scrollbar';
    import LeftMenuGroup from "./LeftMenuGroup";
    import securityHelper from "../../utils/securityHelper";
    import ResizeSensor from 'css-element-queries/src/ResizeSensor';
    import {mapState} from "vuex";
    import centrifugeService from "@/utils/CentrifugeService";

    export default{
        name: 'LeftMenu',
        data() {
            return{
                menu: [],
                menuConfig: [],
                isOpen: true,
                hasModuleCommunications: true,
            }
        },
        props:[
            'menuComplet'
        ],
        beforeMount() {
            this.loadMenu();
            this.$store.commit("setForceMenu", false);
            this.$store.dispatch('getModulesList').then(() => {
                //this.hasModuleCommunications = this.$store.getters.hasModule(this.$t('module.Communications'))
                //console.log("HAS MODULE COMMUNICATIONS --> ", this.$store.getters.hasModule(this.$t('module.Communications')))
            })
        },
        mounted() {
            this.$store.dispatch("currentPersonRoles").then(() => {});
            let configMenuOpen = this.$store.getters.getConfigMenuOpen;
            //console.log("Config menu open: " + configMenuOpen);
            if(configMenuOpen === true || configMenuOpen == 'true'){
                this.openNav();
            }else{
                this.closeNav();
            }
            let self = this;
            this.$nextTick(function () {
                self.resizeFunction();
            });
        },
        computed:{
            arrowClass(){
                if (this.isOpen){
                    return 'icon-arrow-triangle-down'
                }
                else{
                    return 'icon-arrow-triangle-right';
                }
            },
            canViewConfigMenu(){
                let canView = false;
                let currentRoles = this.currentRoles;
                let rolesCan = [
                    "groupRole.SUPER_ADMIN",
                    "groupRole.MULTI_ADMIN",
                    "groupRole.ADMIN",
                    "groupRole.PAS",
                    "groupRole.COORDINATOR",
                    "groupRole.CHIEF_DEPARTMENT",
                    "groupRole.SECRETARY",
                    "groupRole.EAP",
                ];
                let rolesCant = [
                    "groupRole.PARENT",
                    "groupRole.STUDENT",
                    "groupRole.POTENTIALSTUDENT",
                    "groupRole.POTENTIALPARENT"
                ];
                if(typeof currentRoles == "object") {
                    currentRoles.some(function (role) {
                        if((rolesCan.includes(role.keyName) && role.isSystem == 1) || role.isSystem == 0){
                            canView = true;
                            return true;
                        }
                    });
                }
                if(!this.menuConfig || (this.menuConfig && this.menuConfig.length == 0)){
                    canView = false;
                }
                return canView;
            },
            ...mapState({
                menuStore: state => state.menu.menu,
                configStore: state => state.menu.config,
                currentRoles: state => state.persons.currentPersonRoles,
                centerModules: state => state.common.modulesList,
                currentUser: state => state.users.currentUser,
            }),
        },
        watch: {
            menu: {
                handler(newValue, oldValue){
                    if(newValue != oldValue && Array.isArray(newValue)){
                        let indexSurvey = newValue.findIndex(n => n.name == "surveys");
                        if(indexSurvey > -1){
                            this.$set(this.menu[indexSurvey], "badge", this.currentUser.unreadSurveys);
                            this.centrifugoSubscription(indexSurvey);
                        }
                    }
                }
            },
            menuStore: {
                handler(newValue) {
                    this.menu = newValue;
                    if(this.menu != "pending" && !this.hasModuleCommunications){
                        this.menu = this.menu.filter(obj => obj.name !== "communications");
                    }
                    this.setMenuMainHeight();
                    sessionStorage.menu = JSON.stringify(this.menu);
                },
                deep: true
            },
            configStore: {
                handler(newValue) {
                    this.menuConfig = newValue;
                    sessionStorage.menuConfig = JSON.stringify(this.menuConfig);
                    this.setMenuConfigHeight();
                },
                deep: true
            },
            centerModules:{
                handler(newValue){
                    if(newValue != null && newValue != "pending"){
                        this.hasModuleCommunications = this.$store.getters.hasModule(this.$t('module.Communications'));
                    }
                },
                deep: true 
            },
            "currentUser.unreadSurveys": {
                handler(newValue, oldValue){
                    if(newValue != oldValue && Array.isArray(this.menu) && this.menu.length > 0){
                        let indexSurvey = this.menu.findIndex(n => n.name == "surveys");
                        this.$set(this.menu[indexSurvey], "badge", newValue);
                    }
                }
            }
        },
        components: {
            LeftMenuGroup,
            VueScrollbar,
        },
        methods: {
            openNav() {
                //console.log("openNav - ",$("#menuConfig"));
                $("#menuConfig").css('top','122px');
                this.$store.commit("setConfigMenuOpen", true);
            },
            closeNav() {
                //console.log("closeNav - ", $("#menuConfig"), " - pos: ",($(window).height()-50));
                this.isOpen = false;
                this.$store.commit("setConfigMenuOpen", false);
                $("#menuConfig").css('top', ($(window).height()-40)+'px');
            },
            open() {
                this.isOpen = !this.isOpen;
                if (this.isOpen) {
                    this.openNav();
                } else {
                    this.closeNav();
                    this.setMenuMainHeight();
                }
            },
            hasChildren(items) {
                if (items.children.length > 0) {
                    return true;
                }
                return false;
            },
            toggle(id) {
            //   console.log("- Toggling: " + id);
                $('#child_' + id).slideToggle();
                $('#menu_' + id + ' i').toggleClass("icon-arrow-triangle-right").toggleClass("icon-arrow-triangle-down");
            },

            mouseOver(id) {
                // console.log(id);
                this.toggle(id);
            },

            toggleMenu(){
                if ($(window).width() <= 767){
                    $(".navbar-brand span").hide();
                    $("body").addClass("mini-sidebar");
                }
                else{

                }
            },
            loadMenu(){
                if(sessionStorage.menu && Array.isArray(sessionStorage.menu) &&  sessionStorage.menu.length > 0){
                    this.menu = JSON.parse(sessionStorage.menu);
                    this.menuConfig = JSON.parse(sessionStorage.menuConfig);
                }
                else{
                    this.$store.dispatch("loadMenu");
                }
            },
            closeMenu(){
                let windowWidth = $(window).width();
                if(windowWidth <= 767){
                    if (!document.body.classList.contains( 'mini-sidebar' )){
                            document.body.classList.add( 'mini-sidebar' );
                    }
                }
            },
            resizeFunction(){
                let self = this;
                new ResizeSensor($('#menuConfig'), function(a){
                    self.setMenuConfigHeight();
                });

                new ResizeSensor($('#menuMain'), function(a){
                    self.setMenuMainHeight();
                });

                $(window).resize(function () {
                    if(!self.isOpen){
                        $("#menuConfig").css('top', ($(window).height()-40)+'px');
                        self.setMenuMainHeight();
                        self.setMenuConfigHeight();
                    }
                })
            },
            setMenuConfigHeight(){
                let offset = $('.ieduca-scrollbar-config').offset();
                let topRelativeToWindow = 161;
                // if(offset){
                //     topRelativeToWindow = offset.top;
                // }
                let windowHeight = $(window).height();
                let tableBodyHeight = windowHeight - topRelativeToWindow - 25;
                $('.ieduca-scrollbar-config').css('height', tableBodyHeight);
            },
            setMenuMainHeight(){
                let topRelativeToWindow = 125;
                // let offset = $('.ieduca-scrollbar-general').offset();
                // if(offset){
                //     topRelativeToWindow = offset.top;
                // }
                let windowHeight = $(window).height();
                let tableBodyHeight = windowHeight - topRelativeToWindow - 75;
                $('.ieduca-scrollbar-general').css('height', tableBodyHeight + 33);
                // console.log('topRelativeToWindow', topRelativeToWindow, '\nwindowHeight', windowHeight, '\ntableBodyHeight', tableBodyHeight)
            },
            async centrifugoSubscription(indexSurvey){
                let _self = this;
                centrifugeService.getConnection().then((connection) => {
                    let url = new URL(window.location.href);
                    let urlParts = url.hostname.split(".");
                    let clientCode = urlParts[0];
                    let userChannel = "$new-survey-" + this.currentUser.person.id + "-" + clientCode;
                    // console.log('Subscribing to channel', userChannel);
                    const sub = connection.newSubscription(userChannel);
                    sub.on('subscribed', function (ctx) {
                        this.$set(this.menu[indexSurvey], "badge", this.currentUser.unreadSurveys);
                        // console.log('subscribed to channel', ctx);
                    }).on('unsubscribed', function (ctx) {
                        // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
                    }).on('publication', function(ctx) {
                        // console.log('S han publicat dades', ctx.data);
                        let surveys = this.lodash.cloneDeep(this.currentUser.unreadSurveys);
                        surveys++;
                        this.$set(this.menu[indexSurvey], "badge", surveys);
                        _self.$store.commit("setCurrentUserSurveys", surveys);
                    }).subscribe();
                }).catch((error) => {
                    console.error('Error en la connexió', error);
                });
            },
        },
    }
</script>

<style lang="scss">
    @import "../../assets/css/variables";
    @import url("../../assets/css/ieduca_font.css");
    @import url("../../assets/css/ieduca_font_2018.css");
    @import "vue2-scrollbar/dist/style/vue2-scrollbar.css";

    .left-sidebar {
        position: fixed;
        width: 240px;
        height: 100%;
        top: 0;
        z-index: 1000;
        margin-top: $heightHeaderDesktop;
        background: $blackIeduca;
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        transition: 0.3s;
        padding-bottom: 65px;
        color: $white;
        li a {
            padding-left: 0px;
            padding-right: 0px !important;
            opacity: 1;
        }
        .sidebarnavClass {
            ul.menu-dropdown, ul.menu-dropdown li {
                width: 240px;
            }
            .subMenuTitle {
                display: none;
            }
        }
        .arrow:before {
            font-family: ieduca;
            font-style: normal;
            content: "\49";
        }
        a:hover i {
            color:#ffffff;
        }
        ul > .icon-menu {
            padding-left: 10px;
        }
        .overlay {
            width: 240px;
            transition: 0.3s;
        }
    }

    .vue-scrollbar__wrapper {
        background: transparent;
    }

    .fix-element-menu {
        height: 55px;
        padding-top: 15px;
        cursor: pointer;
        opacity: 1;
    }

    #menuConfig .left-sidebar a, .left-sidebar i,
    .fix-element-menu, .left-sidebar li {
        &:hover{
            color: #ffffff !important;
            opacity:1 !important;
            z-index: 9999999;
        }
    }

    #config {
        bottom: 0px;
        .vue-scrollbar__wrapper {
            background-color: $brand1;
        }
        .vue-scrollbar__scrollbar-vertical {
            z-index: 0;
        }
        .mini-sidebar .vue-scrollbar__scrollbar-vertical {
            z-index: 0;
        }
    }

    .vue-scrollbar__scrollbar-vertical {
        z-index: 0;
        .scrollbar {
            background: #A4A4A4;
            color: $white;
            opacity: 0.3;
            z-index: 0;
        }
    }

    .sidebar-nav {
        background: $blackIeduca;//$blackIeduca;;
        padding: 0;
        transition: 0.3s;
        color: $white;
        .has-arrow {   // TODO: .has-arrow ja no hi és, no?
            position: relative;
            &:after {
                position: absolute;
                content: '';
                width: 7px;
                height: 7px;
                border-width: 1px 0 0 1px;
                border-style: solid;
                border-color: #607d8b;
                right: 1em;
                -webkit-transform: rotate(135deg) translate(0, -50%);
                -ms-transform: rotate(135deg) translate(0, -50%);
                -o-transform: rotate(135deg) translate(0, -50%);
                transform: rotate(135deg) translate(0, -50%);
                -webkit-transform-origin: top;
                -ms-transform-origin: top;
                -o-transform-origin: top;
                transform-origin: top;
                top: 47%;
                -webkit-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
                transition: all 0.3s ease-out;
            }
            &[aria-expanded=true]:after {
                -webkit-transform: rotate(-135deg) translate(0, -50%);
                -ms-transform: rotate(-135deg) translate(0, -50%);
                -o-transform: rotate(-135deg) translate(0, -50%);
                top: 45%;
                width: 7px;
                transform: rotate(-135deg) translate(0, -50%);
            }
        }
        .active > .has-arrow:after {
            -webkit-transform: rotate(-135deg) translate(0, -50%);
            -ms-transform: rotate(-135deg) translate(0, -50%);
            -o-transform: rotate(-135deg) translate(0, -50%);
            top: 45%;
            width: 7px;
            transform: rotate(-135deg) translate(0, -50%);
        }
        li > .has-arrow.active:after {
            -webkit-transform: rotate(-135deg) translate(0, -50%);
            -ms-transform: rotate(-135deg) translate(0, -50%);
            -o-transform: rotate(-135deg) translate(0, -50%);
            top: 45%;
            width: 7px;
            transform: rotate(-135deg) translate(0, -50%);
        }
        .sidebarnavClass {
            > li:hover {
                ul {
                    min-height: 36px;
                    height: auto !important;
                    overflow: none;
                    display: block;
                }
                .arrow:before{
                    font-family: ieduca;
                    content: "\e0b8";
                    font-style: normal;
                }
            }
        }
    }

    #menuConfig {
        bottom: 0px;
        position: fixed;
        overflow: hidden;
        width: 240px;
        background-color: $brand1;
        transition: 0.3s;
        .left-sidebar li {
            background-color: $brand1;
        }
        a{ color: $white !important; }
        .subMenuTitle, .sidebarnavClass .li-item.expanded{
            background-color: $brand2_less;
        }
        nav.sidebar-nav a.active {
            color: white !important;
            background-color: $brand2;
        }
        .li-item.expanded:is(:hover) .menu-dropdown {
        background-color: transparent;
    }
    }
    .config-button {
        padding-top: 0.7rem;
        background-color: $brand2_less;
        transition: 0.3s;
        cursor: pointer;
        padding-bottom: 0.5rem;
        position: relative;
        .span-text { font-weight: 700; }
        > a { color: $white !important; }
        .icon-arrow-triangle-down { padding-left: 0px !important; }
    }

    .fix-sidebar .left-sidebar {
        position: fixed;
    }

    .mini-sidebar {
        .vue-scrollbar__scrollbar-vertical {
            z-index: -1;
        }
        .vue-scrollbar__area {
            width: 70px;
            transition: 0.3s;
        }
        .sidebar-nav {
            width: 70px;
        }
        .left-sidebar {
            .title-group-separator {
                margin: 1rem auto !important;
            }

            .icon-fix-menu {
                padding-right: 1.72rem;
                padding-left: 1.72rem;
                vertical-align:middle;
                display: flex;
                font-size: 1.2rem !important;
            }

            .ieduca-scrollbar-config .li-titel:first-child,
            .icon-arrow-triangle-right,
            .icon-arrow-triangle-down,
            .icon-arrow-triangle-up {
                display: none !important;
            }
            .sidebarnavClass {
                .menu-dropdown {
                    display:none;
                }
                li.subMenuTitle {
                    display: block;
                    height: 34px;
                }
                ul.menu-dropdown.hasChildren{
                    margin-top: 0rem;
                }
            }
        }
        .menu-dropdown {
            li {
                padding-left: 1rem;
            }
            &.hasChildren{
                margin-top: -3.35rem;
            }
        }
        .left-sidebar, #menuConfig {
            width: 70px;
        }
        .left-sidebar .fix-element-menu .span-text,
        .left-sidebar #menuConfig .config-button  .span-text,
        .left-sidebar .sidebarnavClass > li > a > .span-text,
        .sidebar-nav .sidebarnavClass > li > a i.arrow-right:before,
        .left-sidebar .sidebarnavClass > li > a div > .span-text,
        .sidebar-nav .sidebarnavClass > li > a div i.arrow-right:before {
            display: none;
        }

    }

    .icon-fix-menu {
        font-size: 1rem !important;
        padding-right: 0.7rem;
        padding-left: 1.72rem;
        vertical-align:middle;
        display: flex;
    }

    .icon-menu {
        font-size: 25px !important;
        margin-left: 10px !important;
        margin-right: 15px !important;
        vertical-align:middle;
        display: inline-block;
    }

    .li-item {
        height: 36px !important;
        line-height: 36px;
    }

    .lastElementMenuGroup .subMenuTitle {
        height: 36px;
    }

    .config-item-wrapper .icon-menu {
        font-size: 20px;
        padding-left: 3px !important;
    }

    .arrow-right {
        top: 0.25rem;
        right: 0.5rem;
        position: absolute;
    }

    .titelGroup {
        opacity: 0.75 !important;
        font-size: 14px;
        padding-left: 1.77rem;
        /* padding-top: 1rem; */
        display: block;
        height: 36px;
        line-height: 36px;
        margin-top: 0.4rem;
    }

    .min {
        display: none;
        margin-left: -0.5rem;
    }

    .config-item-wrapper .min {
        margin-left: -0.2rem;
    }

    #menuMain {
        .sidebarnavClass .li-item,
        .menu-dropdown li:not(.subMenuTitle) {
            &:hover{
                background-color: $brand2_less !important; /*$blackIeduca*/
            }
        }
        .subMenuTitle, .sidebarnavClass .li-item.expanded{
            background-color: $brand2_less !important;
        }
        nav.sidebar-nav a.active {
            color: white !important;
            background-color: $total-black;
        }
        a:not(.router-link-exact-active),
        .homeLink {
            color: #dcdcdc !important;
        }
        .active {
            color: #dcdcdc !important;
        }
    }

    .fix-header #menuMain .sidebarnavClass .li-item.expanded,
    #menuMain .menu-dropdown,
    .left-sidebar #menuMain .menu-dropdown li,
    #menuMain .sidebarnavClass .li-item.expanded {
        background-color: $brand2_less;
    }

    #menuConfig .menu-dropdown,
    .left-sidebar #menuConfig .menu-dropdown li,
    #menuConfig .sidebarnavClass .li-item.expanded {
        background-color: $brand2_less;
    }

    .fix-header #menuConfig .sidebarnavClass .li-item.expanded {
        background-color: $brand2; //#315692;
        li:hover {
            background-color: $brand2;
        }
    }

    .mini_sidebar #menuConfig .sidebarnavClass .li-item.expanded,
    .mini_sidebar #menuConfig .subMenuTitle,
    #menuConfig .li-item:hover {
        background-color: $brand2;
    }

    .subMenuTitle {
        height: 36px;
    }

    .menu-dropdown,
    .left-sidebar .menu-dropdown li,
    .sidebarnavClass .li-item.expanded > a {
        opacity: 1;
    }

    .menu-dropdown li {
        padding-left: 3.5rem;
    }

    .span-text {
        font-size: 16px;
        line-height: 1;
    }

    .config-item-wrapper .span-text {
        padding-left: 2px;
    }

    .overlay {
        height: 0%;
        position: fixed;
        z-index: 1;
        bottom: 0;
        left: 0;
        background-color: $brand1;
        overflow-y: hidden;
        overflow-x: hidden;
        transition: 0.5s;
        a {
            padding: 8px 8px 0 0;
            text-decoration: none;
            color: #818181;
            display: block;
            transition: 0.3s;
            &:hover, &:focus { color: $brand1; }
        }
        .menu-dropdown{
            background-color: #3f64a2;
        }
    }

    .overlay-content {
        position: relative;
        top: 25%;
        width: 100%;
        text-align: center;
        margin-top: 30px;

    }

    .arrow-up {
        padding-right:9px;
    }

    .arrow-down {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.5rem;
    }

    .li-titel .config-item-wrapper {
        height: 36px !important;
        line-height: 36px;
        margin-top: 8px;
    }

    #config-item .align-items-center {
        color: $white;
    }

    .height-initial {
        height: initial;
    }

    .ieduca-scrollbar-wrapper {
        background: transparent;
        margin: 0 auto;
        /*overflow: hidden;*/
        position: relative;
    }

    .ieduca-scrollbar-general, .ieduca-scrollbar-config {
        overflow-y: auto;
        overflow-x: hidden;
        scrollbar-width: thin;
        scrollbar-color: auto;
    }

    @media (max-width: 1199px){
    }
    @media (min-width: 767px) {
        .mini-sidebar .sidebar-nav .overlay .sidebarnavClass {
            > li:hover > a {
                width: 260px;
                background: $brand1;
                height: 42px;
                position: fixed;
            }

            > li > div:hover > a {
                width: 260px;
                background: $brand1;
                height: 55.5px;
                position: fixed;
            }
        }
    }

    @media screen and (max-height: 767px) {
        .overlay {
            overflow-y: auto;
            a { font-size: 20px }
            .closebtn {
                font-size: 40px;
                top: 15px;
                right: 35px;
            }
        }
    }

    @media (max-width: 767px){
        .sidebar-nav{
            width: 100%;
        }
        .mini-sidebar {
            .left-sidebar, #menuConfig, .open-config{
                display: none;
            }
        }

        .left-sidebar .overlay{
            width: 100% !important;
        }
    }

    @media (max-width: 991px) {
        .mini-sidebar {
            .icon-fix-menu {
                padding-right: 1.2rem !important;
            }
            #menuConfig {
                width: 70px;
            }
        }
        .left-sidebar {
            .sidebarnavClass .subMenuTitle {
                display: none;
                padding-right: 1.2rem;
            }
            .titelGroup, .span-text {
                font-size: 18px;
            }
        }
        .arrow-right {
            float: right;
            // padding-top: 0.5rem;
            padding-right: 0.3rem;
        }
    }

    @media (min-width: 768px) {
        // res
    }
    @media (max-width: 480px){
        .titelGroup{
            padding-left: 1rem !important;
        }
        .icon-fix-menu {
            font-size: 1.2rem !important;
            padding-left: 1rem !important;
        }
        .li-item {
            height: 40px !important;
            line-height: 40px;
        }
    }
    @media only screen and (max-width: 450px) {
        .navbar-header {
            display:none;
        }
        .left-sidebar {
            width: 100% !important;
            height: 100% !important;
            transition: width 2s;
            .overlay {
                transition: width 2s;
                -webkit-transition: width 2s;
                width: 100% !important;
            }
        }
        #menuConfig {
            width: 100% !important;
        }
        .mini-sidebar .left-sidebar {
            transition: width 2s;
            -webkit-transition: width 2s;
            display: none;
        }
    }
</style>
