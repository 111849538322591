<template>
    <div id="welcome">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-12 text-center" id="logo-container">
                <template v-if="logo !== 'pending'">
                    <img v-if="logo" :src="logo" alt="clientlogo"/>
                    <img v-else :src="ieducaLogo" alt="ieducalogo"/>
                </template>
            </div>
        </div>
        <div>
            <div class="row">
                <div class="col-md-12 col-sm-12 col-12 text-center no-padding">
                    <h1 class="welcome">{{$t("welcome")}}</h1>
                </div>
            </div>
            <div class="row">
                <div class="col text-center">
                    <p class="moving-fordward">MOVING FORWARD</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Image from '../../utils/image.js';
    import ClientApi from '@/api/core/client';

    export default {
        name: "welcome",
        data() {
            return{
                logo: "pending",
                ieducaLogo: null,
                fonsLogo: null,
            }
        },
         mounted() {
             this.getClientImage();
             this.getImage();
         },
        methods:{
            getImage(){
               this.ieducaLogo = Image.getImageApp('logo_ieduca_lila_180.png');
            },
            getClientImage(){
                let codiClientIni = document.domain.indexOf("://") + 1;
                let codiClientFi = document.domain.indexOf(".");
                let clientCode = document.domain.substring(codiClientIni, codiClientFi);
                let self = this;
                self.logo = null;
                ClientApi.clientImage(clientCode).then(response  => { //Ho comento perquè a producció dóna error 401.
                    if(response){
                        self.logo = response;
                        //self.fonsLogo =  { 'background-image': "url('data:image/png;base64," + self.logo + "')", 'background-repeat': 'no-repeat', 'background-position': 'center' };
                    }
                    else{
                        self.logo = null;
                    }
                });
            }
        }
    }
</script>
<style scoped>
      .welcome{
        color: var(--ieducaColor);
        font-family: Lato;
        font-size: 20px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 1.1px;
        color: #455f9b;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .moving-fordward{
        font-family: Lato;
        font-size: 12px;
        font-weight: 900;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 4.3px;
        color: #a4a4a4;
    }

     @media (max-width: 420px){
         .col-10{
            flex: none !important;
            max-width: 100% !important;
         }
     }
     @media (max-width: 350px){
         /* #logo-container{
             padding-left: 10px;
         } */
     }

</style>
