import entityHelper from '@/api/entityHelper';
import axios from "axios";

export default {
    findStages(filters, page, pageSize, sortField, sortOrder) {
        return entityHelper.findEntity("/stages", filters, page, pageSize, sortField, sortOrder);        
    },
    getStagesCourseByTypeAndCommerce(){
        return axios.get('/stages/getByTypeAndCommerce');
    },
    getStagesSchema(schema) {
        return axios.get('/stages-schema/' + schema);
    }
}