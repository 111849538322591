<template>
    <div class="header" id="header-navbar">
        <b-navbar class="top-navbar navbar-expand-md">
            <!-- Logo -->
            <div class="navbar-header">
                <a class="navbar-brand" href="/">
                    <span class="icon-logo-image"><img :src="logoImage" id="ieduca-logo" alt="homepage" class="dark-logo"/></span>
                    <span class="text-logo-image"><img :src="logoMini" id="ieduca-logo-text" alt="homepage" class="dark-logo"/></span>
                </a>
            </div>
            <!-- End Logo -->
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="center-navbar mr-auto">
                    <b-nav-item @click="toggleMenu()"><i class="hamburger fa fa-bars"></i></b-nav-item>
                    <div v-if="currentCenter && centers && centers.length < 1"
                        class="center-info vertical-center-inside">
                        <div v-if="currentCenter.centerCode === 'multicenter'" class="center-icon"><i class="icon icon-globe-2"></i></div>
                        <div v-else-if="currentCenter.logoBase64 || client.logo" class="center-icon" :style="getCenterImage(currentCenter)"></div>
                        <div v-else class="center-icon"><i class="icon icon-centre-01"></i></div>
                        <div class="center-nameyear pl-3">
                            <div class="center-name" v-if="currentCenter">{{ currentCenter.name }}</div>
                            <div class="center-year" v-if="yearName && yearName!=='null'">{{ yearName }}</div>
                        </div>
                    </div>
                    <b-nav-item-dropdown :disabled="centers && centers.length < 1" v-else-if="currentCenter" toggle-class="center-selector" menu-class="center-options ieduca-dropdown">
                        <template v-slot:button-content>
                            <div class="center-info">
                                <div v-if="currentCenter.centerCode === 'multicenter'" class="center-icon"><i class="icon icon-globe-2"></i></div>
                                <div v-else-if="currentCenter.logoBase64 || client.logo" class="center-icon" :style="getCenterImage(currentCenter)"></div>
                                <div v-else class="center-icon"><i class="icon icon-centre-01"></i></div>
                                <div class="center-nameyear">
                                    <div class="center-name" v-if="currentCenter">{{ currentCenter.name }}</div>
                                    <div class="center-year" v-if="yearName && yearName!=='null'">{{ yearName }}</div>
                                </div>
                            </div>
                        </template>
                        <template v-slot:default>
                            <b-dropdown-item @click="changeCenter(item)" v-for="item in centers" :key="item.centerCode">
                                <span v-if="item.centerCode === 'multicenter'" class="center-option-icon"><i class="icon icon-globe-2"></i></span>
                                <span v-else-if="item.logoBase64 || client.logo" class="center-option-logo" :style="getCenterImage(item)"></span>
                                <span v-else class="center-option-icon"><i class="icon icon-centre-01"></i></span>
                                <span class="center-option-name" v-html="item.name"></span>
                                <span class="center-option-label" v-html="item.label"></span>
                            </b-dropdown-item>
                        </template>
                    </b-nav-item-dropdown>
                </b-navbar-nav>

                <b-navbar-nav class="user-navbar">
                    <!-- Messages -->
                    <b-nav-item-dropdown menu-class="ieduca-dropdown" right  v-if="hasModuleCommunications && $can('view', 'Messaging')">
                        <template v-slot:button-content>
                            <div  class="user-mails topIconMessage"><alert-icon icon="icon-mail-alt" :number="unreadMessages" extraCss="cursorPointer"></alert-icon></div>
                        </template>
                        <template v-slot:default>
                            <div v-show="unreadMessages>0" class="ml-2 mr-5"><strong>{{ $tc('messaging.newAlert', unreadMessages)}}</strong></div>
                            <b-dropdown-item class="containerMessage" v-for="unreadMessage in listUnreadMessages" @click="$router.push('/messaging/view/'+unreadMessage.id+'/inbox')"
                                             :key="'linkMenuUnread'+unreadMessage.id">
                                <div class="colMessage ">
                                    <user-image 
                                        v-if="unreadMessage.sender" 
                                        :userId="unreadMessage.sender.id"
                                        :name="unreadMessage.sender.name" 
                                        :surname="unreadMessage.sender.surname1"
                                        :extraClass="'small'" 
                                        :disable-tooltip="true" 
                                    />
                                    <user-image 
                                        v-else 
                                        :name="$t('rmtIeduca')" 
                                        :surname="'iEduca'"
                                        :extraClass="'small'" 
                                        :disable-tooltip="true"
                                    />
                                </div>
                                <div class="colMessage colMessageText pl-3 pr-3">
                                    <strong v-if="unreadMessage.sender">{{ getSenderCompleteName(unreadMessage.sender) }}<br/></strong>
                                    {{unreadMessage.subject}}
                                </div>
                                <div class="colMessage ">
                                    {{ getRecibedHour(unreadMessage.createdAt)}}
                                    <br/><small>{{getRecibedDay(unreadMessage.createdAt)}}</small>
                                </div>
                            </b-dropdown-item>
                            <b-dropdown-divider v-if="listUnreadMessages?.length > 0"></b-dropdown-divider>
                            <b-dropdown-item class="text-center" @click="$router.push('/messaging')" > {{ $t('messaging.misinbox') }}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item class="text-center" @click="$router.push({name: 'composeInternal'})" ><i id="new-message-icon-plus" class="icon-plus-alt2 mr-1"></i> {{ $t('messaging.compose') }}</b-dropdown-item>
                        </template>
                    </b-nav-item-dropdown>
                    <!-- End Messages -->

                    <!-- Profile -->
                    <b-nav-item-dropdown  v-if="user && user!='pending'"
                                          toggle-class="user-basic-info" menu-class="user-options ieduca-dropdown" right>
                        <template v-slot:button-content>
                            <user-image
                                :key="user.id"
                                :userId="user.id"
                                :name="user.name"
                                :surname="user.surname1"
                                :extraClass="'profile-image' + (smallScreenSize ? ' verysmall' : '')"
                                :imgSrc="user.photo"
                                :disable-tooltip="true"></user-image>
                        </template>
                        <template v-slot:default>
                            <b-dropdown-header class="user-header-summary">
                                <user-image
                                    :key="user.id"
                                    :userId="user.id"
                                    :name="user.name"
                                    :surname="user.surname1"
                                    :extraClass="'large d-block text-align-center'"
                                    :imgSrc="user.photo"
                                    :disable-tooltip="true"></user-image>
                                <div class="user-name">{{ displayName }}</div>
                                <div class="user-username">{{ user.email }}</div>
                            </b-dropdown-header>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="goProfile" class="user-profile-option"><i class='icon-user'></i> {{ $t('profile') }}</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item @click="logout" class="user-logout-option"><i class="fas fa-power-off"></i> {{ $t('signout') }}</b-dropdown-item>
                        </template>
                    </b-nav-item-dropdown>
                    <div v-else @click="logout" class="user-logout-option error"><i class="fas fa-power-off"></i></div>
                    <!-- End Profile -->
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
    import Logo from '@/assets/image/core/simbol_ieduca_fonstransparent.png';
    import LogoMini from '@/assets/image/core/txtlogo_ieduca_fonstransparent.png';
    import DropdownBox from '../components/dropdown/DropdownBox';
    import Dropmic from 'dropmic';
    import UserImage from '../components/UserImage';
    import { BTooltip, BNavbar, BNavbarNav, BNavItemDropdown, BNavbarBrand, BNavItem, BCollapse, BDropdownItem, BDropdownHeader, BDropdownDivider } from 'bootstrap-vue';
    import $ from "jquery";
    import AlertIcon from '../components/AlertIcon';
    import axios from 'axios';
    import TopMenuApi from '@/api/core/topMenu';
    import UserApi from '@/api/core/users';
    import messagingApi from '@/api/messaging/messaging'
    import { mapState } from 'vuex';
    import moment from "moment";
    import store from "../store/store";
    import centrifugeService from "@/utils/CentrifugeService";
    import { Centrifuge } from 'centrifuge';

    // import Popper from 'popper.js'

    export default {
        name: 'topMenu',
        components: {
            DropdownBox,
            AlertIcon,
            UserImage,
            BNavbar,
            BNavbarNav,
            BNavItemDropdown,
            BNavbarBrand,
            BNavItem,
            BCollapse,
            BDropdownItem,
            BDropdownHeader,
            BDropdownDivider,
            BTooltip
        },
        props: [
            'centersInicial',
            'userInicial'
        ],
        data() {
            return {
                socket: null,
                desplegat: false,
                logoImage: Logo,
                logoMini: LogoMini,
                alerts: null,
                centers: [],
                currentCenter: null,
                message_header: null,
                userIcon: null,
                user_header: null,
                user_footer: "<span class='user_footer'><i class='fas fa-power-off'></i> " + this.$i18n.tc('logout') + "</spanthis.$i18n.tc>",
                user_image: null,
                multicenter: false,
                multicenterData: {
                    value: 'multicenter',
                    centerCode: 'multicenter',
                    name: this.$options.filters.capitalize(this.$tc('multicenter')),
                    id: 'multicenter'
                },
                cancelToken: null,
                hasModuleCommunications: true,
                // showDrop: false
            }
        },
        computed: {
            currentCenterImage() {
                if (this.currentCenter.logoBase64 != "" && typeof this.currentCenter.logoBase64 != 'undefined' && this.currentCenter.logoBase64 != null) {
                    return 'data:image/png;base64,' + this.currentCenter.logoBase64;
                } else if(this.client.logo){
                    return this.client.logo;
                }

                return "";
            },

            ...mapState({
                expandedMenuId: state => state.expandedMenuId,
                centerId: state => state.currentCenter.centerId,
                yearName: state => state.currentCenter.yearName,
                allPersonRoles: state => state.persons.allPersonRoles,
                currentUser: state => state.users.currentUser,
                //user: state => state.users.currentUser.person,
                //listUnreadMessages: state => state.users.currentUser.listUnreadMessages,
                client: state => state.client,
                deviceSize: state => state.deviceSize,
                centerModules: state => state.common.modulesList
            }),
            user(){
                if(this.currentUser){
                    return this.currentUser.person;
                }
            },
            listUnreadMessages(){
                if(this.currentUser){

                    return this.currentUser.listUnreadMessages;
                }
            },
            displayName(){
                if (this.user && this.user.name === 'undefined' || this.user.name === "") {
                    return this.user.username;
                } else {
                    return this.user.name + ' ' + this.user.surname1;
                }
            },
            // classDropDown(){
            //     if(this.centerId ==='multicenter'){
            //         return 'global-center'
            //     }
            //     return '';
            // },
            unreadMessages(){
                if(Array.isArray(this.listUnreadMessages)){
                    return this.currentUser.unreadMessages; //this.listUnreadMessages.length; -> Això està malament pq limitem a 9 max i potser n'hi ha més de no llegits!
                }
                else{
                    return 0;
                }
            },
            smallScreenSize(){
                return this.deviceSize == 'xs' || this.deviceSize == 'sm';
            },
        },
        beforeMount() {
            this.getCenters();
            this.getUser();
            /*this.$store.dispatch('getModulesList').then(() => {
                this.hasModuleCommunications = this.$store.getters.hasModule(this.$t('module.Communications'))
                console.log("HAS MODULE COMMUNICATIONS --> ", this.$store.getters.hasModule(this.$t('module.Communications')))
            })*/

            // this.socket = new WebSocket('wss://backend.pre.ieduca2.com/websocket');
            // console.log("conn: ",this.socket);
            //
            // // Escuchar eventos de error y cerrar
            // this.socket.onerror = function(event) {
            //     console.error('Error en la conexión WebSocket:', event);
            // };
            //
            // this.socket.onclose = function(event) {
            //     console.log('Conexión WebSocket cerrada.');
            // };
            //
            // this.socket.onmessage = function(e) { console.log(e.data); };
            // this.socket.onopen = function(e) { this.socket.send('Hello Me!'); };
            //
            // this.socket.addEventListener('message', (e) => {
            //     console.log("socket eventList", e.data);
            // })
            //
            // this.socket.onClose = function (e){
            //     console.log("error onClose");
            // }

        },
        mounted(){
            //Hide the navbar when we scroll down, for a resolution lower than 768 width (mobile and small tablets)
            let prevScrollpos = window.pageYOffset;
            window.onscroll = function() {
                let windowWidth = $(window).width();
                let currentScrollPos = window.pageYOffset;
                if (prevScrollpos > currentScrollPos) {
                    document.getElementById("header-navbar").style.top = "0";
                } else {
                    if(windowWidth <= 767){
                        if (!document.body.classList.contains( 'mini-sidebar' )){
                            document.getElementById("header-navbar").style.top = "0";
                        } else {
                            document.getElementById("header-navbar").style.top = "-70px";
                        }
                    }
                }
                prevScrollpos = currentScrollPos;
            }
            if ($("body").hasClass("mini-sidebar")) {
                $(".text-logo-image").hide();
            } else {
                $(".text-logo-image").show();
            }
            let self = this;
            //Hacerlo solo en producción, en local no hace falta y molesta al debugar
            if (process.env.NODE_ENV !== 'development') {
                // TODO: Ternar a l'estat inincial
            }
            // setInterval(() => {
            //     self.getUnreadMessages();
            // }, (1000 * 30));
        },
        watch:{
            expandedMenuId(nv,ov){
                let windowWidth = document.documentElement.clientWidth;
                let liMenu = $(".left-sidebar li.menu"+nv);
                let hasChild = liMenu.hasClass("menuHasChildren");
                //console.log("TopMenu expandedMenuId ",nv, " old: ",ov," -- windowWidth: ",windowWidth, " liMenu: ", liMenu," - hasChild: ",hasChild);
                if(windowWidth<575 && nv!=ov && !hasChild){
                    this.plegarMenu(windowWidth);
                }
            },
            centerId: {
                handler(newValue, oldValue){
                    //console.log("centerId", newValue, oldValue)
                    if(newValue){
                        this.getUser();
                        this.$store.commit("changeCenterName", this.currentCenter.name);
                        let year = {code: this.currentCenter.yearCode, name: this.currentCenter.yearName, activeYear: {yearCode: this.currentCenter.yearCode, yearName: this.currentCenter.yearName}};
                        this.$store.commit("changeYearCode", year);
                    }
                }
            },
            centerModules:{
                handler(newValue){
                    if(newValue != null && newValue != "pending"){
                        this.hasModuleCommunications = this.$store.getters.hasModule(this.$t('module.Communications'))
                    }
                }, deep: true
            },
            currentUser(newValue) {
                if (newValue && newValue !== 'pending') {
                    //console.log('Current user resolved', newValue);
                    this.centrifugoSubscription();
                }
            }
        },
        methods: {
            async centrifugoSubscription(){
                let _self = this;
                centrifugeService.getConnection().then((connection) => {
                    let url = new URL(window.location.href);
                    let urlParts = url.hostname.split(".");
                    let clientCode = urlParts[0];
                    let userChannel = "$new-messages-" + this.currentUser.person.id + "-" + clientCode;
                    //console.log('Subscribing to channel', userChannel);
                    const sub = connection.newSubscription(userChannel);
                    sub.on('subscribed', function (ctx) {
                       // console.log('subscribed to channel', ctx);
                    }).on('unsubscribed', function (ctx) {
                       // console.log(`unsubscribed: ${ctx.code}, ${ctx.reason}`);
                    }).on('publication', function(ctx) {
                       // console.log('S han publicat dades', ctx.data);
                        let messages = {
                            list: ctx.data.list.map(item => {
                                item.createdAt = item.createdAt.date;
                                return item;
                            }),
                            count: ctx.data.count
                        }
                        // console.log("messages", messages);
                        _self.$store.commit("setCurrentUserMessages", messages);
                    }).subscribe();
                }).catch((error) => {
                    console.log('Error en la connexió', error);
                });
            },
            getUnreadMessages(){
                let self = this;
                if (this.cancelToken != "undefined" && this.cancelToken != null) {
                    this.cancelToken.cancel();
                }
                this.cancelToken = axios.CancelToken.source();
                if(this.currentUser != null && this.hasModuleCommunications && this.$can('view', 'Messaging')){
                    messagingApi.getUnreadMessages(this.cancelToken.token).then(response => {
                        if (response.data.status === 'ok') {
                            let messages = {
                                list: response.data.list,
                                count: response.data.count,
                            }
                            self.$store.commit("setCurrentUserMessages", messages);
                        }
                    })
                }
            },
            testWebSocket() {
                //console.log(this.socket);
                console.log("testWebSocket: ",this.socket.readyState);
                //f (this.socket.readyState == WebSocket.OPEN) {
                this.socket.onopen = function(event) {
                    this.socket.send('Hola');
                }
            },
            getRecibedHour(timeRecibed) {
                return moment(timeRecibed).format('HH:mm');
            },
            getRecibedDay(timeRecibed) {
                return moment(timeRecibed).format('D/MMM');
            },
            logout() {
                this.$store.commit('deleteMenus');
                this.$store.dispatch('resetCenterInfo');
                this.$store.dispatch('logout');
                this.$store.commit('resetUserInfo');
                this.$router.push({name: 'Login'});
            },
            count(items) {
                if (items != null) {
                    return items.length;
                } else {
                    return 0;
                }
            },
            plegarMenu(windowWidth){
                $("body").addClass("mini-sidebar")
                $(".text-logo-image").hide();
                $("[name=big]").hide();
                $("[name=min]").show();
                if (windowWidth <= 575) {
                    this.$store.commit('paginator', {paginator: 'mini'});
                } else {
                    this.$store.commit('paginator', {paginator: 'full'});
                }
            },
            toggleMenu() {
                let windowWidth = document.documentElement.clientWidth;
                let value = $("body").hasClass("mini-sidebar");
                this.$store.commit("setCollapsed", !value);
                if ($("body").hasClass("mini-sidebar")) {
                    $("body").removeClass("mini-sidebar")
                    $(".text-logo-image").show();
                    $("[name=min]").hide();
                    $("[name=big]").show();
                    if (windowWidth <= 991) {
                        //console.log("Aquí hauriem de plegar x poder veure el contingut clicat!");
                        this.$store.commit('paginator', {paginator: 'mini'});
                    } else {
                        this.$store.commit('paginator', {paginator: 'full'});
                    }

                } else {
                    this.plegarMenu(windowWidth);
                }
            },
            async changeCenter(center) {
                let that = this;

                return await TopMenuApi.changeCenter(center.centerCode)
                    .then((response) => {
                        let routeName = that.$route.name;
                        let noMulticenterPageList = that.$store.getters.getNoMultiCenterPageList;

                        that.$emit('reloadCenterInfo');
                        that.$store.commit('changeCenterCode', center.centerCode);
                        that.$store.commit('changeCenterName', center.name);
                        that.currentCenter = center;

                        /* this.$refs.dbm_center.close(); */
                        if (center.centerCode === 'multicenter') {
                            that.toast(that.$tc('warning.switch.global'), this.$tc('warning'), 'warning');
                            if (noMulticenterPageList.includes(routeName)) {
                                that.$router.push({ name: 'root' });
                            }
                        }
                    }).catch(e => {
                        that.$store.commit("setLoading", false);
                        that.toast(that.$t(e.error), that.$tc('error'), 'error', true);
                    })
            },
            async getCenters() {
                await axios.get('/centerDropDown').then((response) => {
                    if(response) {
                        let centersArray = response.data;
                        let currentCenter = null;
                        let centers = [];
                        let _self = this;
                        // console.log(centersArray);
                        centersArray.forEach(function (item) {
                            item.name = _self.$tc(item.name);
                            if (item.current) {
                                currentCenter = item;
                                // console.log("center", item);
                                _self.setLanguageIfNeeded(item.info.defaultLanguage);
                                _self.$store.commit('setCurrentCountry', item.centerCountry);
                            } else {
                                centers.push(item);
                            }
                        });
                        this.centers = centers;
                        this.currentCenter = currentCenter;
                        this.$store.commit('saveCenters', {centers});
                        this.$store.commit('changeCenter', {
                            centerCode: currentCenter.centerCode,
                            centerId: currentCenter.info.id
                        });
                        this.$store.commit('saveSchemaName', this.currentCenter.schemaName);
						// console.log("info currentCenter? ", this.currentCenter)
                        this.$store.commit('setExternalSoft', this.currentCenter.externalSoft);
                    }
                }, (error) => {
                    this.loading = false;
                    this.error = error;
                }).catch(error => {
                    // console.log("ERROR en el getCenters ::: " + error)
                });
                this.$forceUpdate();
            },
            setLanguageIfNeeded(centerLanguage){
                let lang = localStorage.language;
                if (centerLanguage && (typeof lang === "undefined" || lang === "undefined" || lang == null || lang === "")) {
                        localStorage.setItem("language", centerLanguage);
                    // console.log("setLanguage", centerLanguage);
                    this.$i18n.locale = centerLanguage;
                }
            },
            getUser() {
                let _self = this;
                //console.log("TopMenu getUser");
                _self.$store.dispatch("currentUser");
            },
            goProfile() {
                this.$router.push({name: 'Profile'});
            },
            getCenterImage(item, originalSize) {
                let logoImage64 = "";

                if (item.logoBase64 != "" && item.logoBase64 != null && typeof item.logoBase64 != 'undefined') {
                    logoImage64 = item.logoBase64;

                    return { 'background-image': "url('data:image/png;base64," + logoImage64 + "')", 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'contain' };
                } else if(this.client.logo){
                    return { 'background-image': "url('" + this.client.logo + "')", 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-size': 'contain' };

                }

                return "";
            },
            getSenderCompleteName(sender){
                return sender.name + " " + sender.surname1 + " " +  sender.surname2;
            }
        }
    }
</script>
<style scoped lang="scss">
    @import "../assets/css/variables.scss";

    .containerMessage{
        width: 600px;
        display: table-row;
        .colMessage{ display: table-cell}
    }

    .header {
        z-index: 1039; /* No superar 1040 */
        background: #fff;
        position: fixed;
        width: 100%;
        height: $heightHeaderDesktop;
        .top-navbar {
            padding: 0;
            height: $heightHeaderDesktop;
            .navbar-header {
                background: $brand1;
                transition: 0.3s;
                height: $heightHeaderDesktop;
                width: 240px;
                position: relative;
                .navbar-brand {
                    display: block;
                    height: 70px;
                    width: 100%;
                    padding: 0;
                    transition: 0.3s;
                    align-content: center;
                    .light-logo {
                        display: none;
                        transition: 0.3s;
                    }
                    .icon-logo-image {
                        display: block;
                        position: absolute;
                        top: 15px;
                        left: 13px;
                        width: 40px;
                        height: 40px;
                        img {
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .text-logo-image {
                        margin-left: 55px !important;
                        margin-top: 10px !important;
                        display: block;
                        padding-left: 8px;
                    }
                }
            }
            .navbar-nav {
                align-items: center;
                > .nav-item {
                    > .nav-link {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        font-size: 18px;
                        line-height: 52px;
                    }
                    &.show {
                        background: rgba(0, 0, 0, 0.05);
                    }
                }
            }
        }
        .profile-pic {
            width: 30px;
            border-radius: 100%;
        }
    }

    .mini-sidebar {
        .header .top-navbar .navbar-header .navbar-brand {
            padding-top: 0;
            transition: 0.3s;
            display: block;
            width: 100%;
            height: 100%;
        }
        .top-navbar .navbar-header {
            width: 70px;
            text-align: center;
            transition: 0.3s;
        }
    }

    .logo-center .top-navbar .navbar-header {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .navbar {
        color: $grey;
        font-size: 1rem;
        font-family: $font;
    }

    .hamburger {
        font-size: 1.3rem;
    }

    .dropdown-toggle {
        display: flex;
        align-items: center;
        margin-top:2px;
        cursor: pointer;
    }

    span.center-selected-info {
        width: auto !important;
    }

    .user-navbar{
        margin-right: 10px !important;
        display: inline-flex;
        align-items: center;
        align-content: center;
    }

    .center-info {
        display: inline-flex;
        vertical-align: middle;

        .center-icon {
            width: 50px;
            height: 50px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            .icon{
                font-size: 2.5rem;
            }
        }
        .center-name {
            margin-top: 3px;
        }
        .center-year {
            font-size: .8rem;
        }
        .center-nameyear{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: center;
            padding-left: 0.5rem;
        }

    }
    // .user-navbar>li:last-of-type {
    //     // margin-top: -2px !important;
    // }
    .user-mails.topIconMessage {
        font-size: 25px;
        line-height: 0;
    }

    #new-message-icon-plus{
        font-size: 13px;
    }

    .navbar-expand .navbar-collapse{
        align-items: stretch;
    }

    #header-navbar{
        transition: top 0.3s; //Smooth transition when the navbar hides
    }

    .center-selector .center-info:not(.vertical-center-inside){
        @media (max-width: 480px){
            max-width: 160px;
        }
        @media only screen and (max-width: 400px){
            max-width: 125px;
        }
        @media only screen and (max-width: 360px) {
            max-width: 105px;
        }
        // @media only screen and (max-width: 340px) {
        //     max-width: 80px;
        // }
    }

    @media (min-width: 1200px) {
        .navbar-header .navbar-brand img {
            vertical-align: baseline;
        }
    }

    @media (max-width: 1199px) {
        .header, .header .top-navbar, .header .top-navbar .navbar-header {
            height: $heightHeaderTablet;
        }
    }

    @media (max-width: 992px){
    }

    @media (max-width: 767px) {
        .containerMessage{ width: 470px; }
    }

    @media (max-width: 520px) {
        .center-info{
            .center-icon {
                display: none;
                .icon{
                    display: none;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .containerMessage{
            width: 300px;
        }

        .navbar-expand .navbar-nav .nav-link {
            padding-right: 0;
            padding-left: 0;
        }
        div.center-info{
            max-width: 10em;
        }
        .center-info {
            display: inline-flex;
            vertical-align: middle;
            overflow: hidden;
            .center-name {
                margin-top: 3px;
                font-size: .9rem;
            }
            .center-year {
                font-size: .7rem;
            }
            .center-nameyear{
                padding-left: 0rem;
            }
        }
        .nav-link{
            &:has(.hamburger) {
                margin: 0;
                padding: 0;
                line-height: 0 !important;
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .user-navbar>li:first-of-type {
            margin-left: -20px;
        }
    }

    @media only screen and (max-width: 360px) {
        .center-navbar li:first-of-type {
            height: fit-content;
        }
    }

    @media only screen and (max-width: 340px) {
        .user-navbar .user-basic-info {
            margin-top: 5px;
        }
    }
</style>
