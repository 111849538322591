<template>
    <div>
      <p>{{ toastBody }}</p>
      <b-button variant="primary" @click="handleClick" class="btn"><b>{{ buttonText }}</b></b-button>
    </div>
</template>

<script>
export default {
    props: {
        toastBody: '',
        buttonText: '',
        emitFunctionName: ''
    },
    methods: {
        handleClick() {
            this.$emit(this.emitFunctionName)
        }
    }
}
</script>