import axios from 'axios';
import rolesApi from '@/api/core/roles';
import cutFunctions from '@/filters/cutFunctions';

const initialState = () => ({
    centerCode: null,
    yearCode: null,
    yearName: null,
    schemaName: null,
    activeYear: false,
    years: null,
    centerId:null,
    roles: null,
    systemRoles: null,
    lastActiveYear: null,
    lastYearCode: null,
    lastYearName: null,
    allProgramsByStage: [],
    tags: null,
    centers:[],
    loadedMultiCenterYears: false,
    teachers: null,
    reloadCenter: 0,
    centerName: null,
    currentCountry: null,
    externalSoft: null
});

export const currentCenter = {
    state: initialState(),
    mutations: {
        resetCenterInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        changeCenterCode(state, center) {
            state.centerCode = center;
            if (state.yearCode != null) {
                state.schemaName = center + '_' + state.yearCode;
            }
        },
        changeCenterName(state, name) {
            state.centerName = name;
        },
        changeYearCode(state, year) {
            if (year == null) {
                state.yearCode = null
                state.activeYear = null
                state.schemaName = null
                state.yearName = null
            } else {
                state.yearCode = year.code;
                state.yearName = year.name;
                state.activeYear = year.activeYear;
                if (state.centerCode != null) {
                    state.schemaName = state.centerCode + '_' + state.yearCode;
                }
                state.lastYearName = year.name;
                state.lastActiveYear = year.activeYear;
                state.lastYearCode = year.code;
            }
        },
        saveYears(state, years){
            state.years = years;
        },
        saveProgramsStage(state, allProgramsByStage){
            state.allProgramsByStage = allProgramsByStage;
        },
        addProgramsStage(state, stageId, allProgramsByStage){
            state.allProgramsByStage[stageId] = allProgramsByStage;
        },
        changeCenter(state,center){
            state.centerCode = center.centerCode;
            state.centerId = center.centerId;
            if (state.yearCode != null) {
                state.schemaName = state.centerCode + '_' + state.yearCode;
            }
        },
        saveSystemRoles(state,roles){
            state.systemRoles=roles;
        },
        saveTags(state, tags){
            state.tags = tags;
        },
        saveRoles(state, roles){
            state.roles = roles;
        },
        saveCenters(state,centers){
            state.centers=centers.centers;
        },
        saveLoadedMultiCenterYears(state, years){
            state.loadedMultiCenterYears = years;
        },
        saveTeachers(state, teachers){
            state.teachers = teachers;
        },
        saveSchemaName(state, schemaName){
            if(schemaName) {
                state.schemaName = schemaName;
                let yearCode = "";
                let pos = schemaName.indexOf(state.centerCode);
                if (pos !== -1) {
                    //console.log("pos: 0!"+pos+" retallem de: "+(pos + state.centerCode.length + 1)+" a "+schemaName.length);
                    yearCode = schemaName.substr(pos + state.centerCode.length + 1, schemaName.length);
                } else {
                    let parts = schemaName.split("_");
                    if (parts.length == 2) {
                        yearCode = parts[1];
                    }
                }
                state.yearCode = yearCode;
            }
        },
        setExternalSoft(state, value){
            state.externalSoft = value;
        },
        setReloadCenter(state, value){
          state.reloadCenter = value;
        },
        setCurrentCountry(state, value){
            state.currentCountry = value;
        },
    },

    getters: {
        currentCenter: state => {
            return state;
        },
        getActiveYear: function(state){
            let activeYear = null;
            let i = 0;
            //console.log("state.years", state.years);
            if(state.years !== undefined && state.years !== null){
                while(i < state.years.length && !activeYear){
                    if (state.years[i].payload === true){
                        //console.log("year: ", state.years[i].label);
                        activeYear = state.years[i];
                        //console.log("currentCenter - activeYear", activeYear);
                    }
                    i++;
                }
            }
            return activeYear;
        },
        allProgramsByStage: function(state){
            return state.allProgramsByStage;
        },
        allProgramsByStageById: function(state, stageId){
            if(state.allProgramsByStage[stageId]===undefined){
                return [];
            }else{
                return state.allProgramsByStage[stageId];
            }
        },
        currentYear: function(state){
            //if(state.yearCode==null) { return state.activeYear; }
            return { yearCode: state.yearCode, yearName: state.yearName };
        },
        currentCenterId: state =>{
            return state.centerId;
        },
        systemRoles: state =>{
            return state.systemRoles;
        },
        tags: state => {
            return state.tags;
        },
        roles: state =>{
            return state.roles;
        },
        centers : state=>{
            return state.centers;
        },
        loadedMultiCenterYears : state=>{
            return state.loadedMultiCenterYears;
        },
        teachers: state => {
            return state.teachers;
        },
        getReloadCenter: state => {
          return state.reloadCenter;
        },
        currentCountry: state => {
            return state.currentCountry;
        },
        externalSoft: state =>{
            return state.externalSoft;
        }
    },
    actions: {
        async getRoles(state) {
            let systemRoles = state.systemRoles;
            let roles = state.roles;
            if (systemRoles == null) {
                systemRoles = [];
                roles = [];
                //console.log("fetchroles");
                await rolesApi.rolesList().then(roles => {
                    roles = roles.data.data;
                    state.commit('saveRoles', roles);
                    for (let i = 0; i < roles.length; i++) {
                        let g = roles[i];
                        g.iconTypeCss = '';
                        if (g.value !== undefined && g.value !== null) {
                            g.iconTypeCss = ' icon icon-' + g.value.toLowerCase() + ' ';
                        }
                        if (g.isSystem === 1) {
                            g.nameTranslated = g.name;
                            systemRoles.push(g);
                        }
                    }
                    state.commit('saveSystemRoles', systemRoles);
                    return {roles: roles, systemRoles: systemRoles};
                });
                return {roles: roles, systemRoles: systemRoles};
            } else {
                return {roles: roles, systemRoles: systemRoles};
            }
        },

        async getYears(context){
            let years = context.state.years;
            //MULTICENTER
            if (context.state.centerCode === "multicenter"){
                if(!context.state.loadedMultiCenterYears) {
                    context.commit('saveYears', "pending");
                    axios.get('/academicyeardropdown').then(response => {
                        let options = [];
                        let centerYears = response.data.data;
                        centerYears.forEach(function (item) {
                            item.centerYears.forEach(function (year) {
                                let yearIndex = options.findIndex(obj => obj.value === year.year.code );
                                if (yearIndex < 0) {
                                    options.push({
                                        'value': year.year.code,
                                        'label': cutFunctions.cutName(year.year.name, 20, false),
                                        'payload': year.isActive,
                                        'validity': year.validity,
                                        'centers': [item.code],
                                        'startDate':year.year.startDate,
                                        'endDate':year.year.endDate,
                                    });
                                }
                                else{
                                    options[yearIndex].centers.push(item.code);
                                }
                            });
                        });
                        context.commit('saveYears', options);
                        context.commit('changeYearCode', null);
                        context.commit("saveLoadedMultiCenterYears", true);
                    }).catch(error =>{
                        context.commit('saveYears', "error");
                        // console.log("ERROR getYears multicenter", error);
                    });
                }
            }
            //CENTER_X
            else if(years === null && context.state.centerCode) {
                context.commit('saveYears', "pending");
                await axios.get('/academicyeardropdown').then(response => {
                    let centerYears = response.data.data;
                    let currentSchema = context.state.centerCode;
                    let options = [];
                    if (centerYears !== undefined) {
                        centerYears.forEach(function (item) {
                            if (item.code === currentSchema) {
                                item.centerYears.forEach(function (year) {
                                    let yearObject = {
                                        'value': year.year.code,
                                        'label': cutFunctions.cutName(year.year.name, 20, false),
                                        'payload': year.isActive,
                                        'validity': year.year.validity,
                                        'schemaName':currentSchema + "_" + year.year.code,
                                        'startDate':year.year.startDate,
                                        'endDate':year.year.endDate,
                                    };
                                    options.push(yearObject);
                                    if (year.isActive){
                                        let defaultOption = {code: yearObject.value, name: yearObject.label, activeYear: yearObject.payload};
                                        context.commit('changeYearCode', defaultOption);
                                    }
                                });
                            }
                        });
                        context.commit('saveYears', options);
                    }
                }).catch(error =>{
                    context.commit('saveYears', "error");
                    // console.log("ERROR getYears center", error);
                });
            }
        }
    }
}

