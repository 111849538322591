export default {
    "contact.data": "Datos de contacto",
    "responsables": "Familiares",
    "person.data" : "Datos Personales",
    "person.basicData" : "Datos básicos",
    "person.lastName": "Apellido {num}",
    "person.dni":"DNI",
    "person.rol":"Permiso",
    "person.gender": "Género",
    "person.address": "Dirección",
    "person.town": "Población",
    "person.zip": "Código Postal",
    "person.state":"Provincia",
    "person.nationality":"Nacionalidad",
    "person.birthday": "Fecha de Nacimiento",
    "person.created": "Fecha de alta",
    "person.SS": "Num. Seguridad Social",
    "person.contact": "Contacto",
    "contact.delete": "¿Seguro que quieres eliminar el contacto '{count}'?",
    "person.relationtype": "Tipo de relación",
    "person.relationtype.parent": "Padre",
    "person.relationtype.mother": "Madre",
    "person.relationtype.tutor": "Tutor/a legal",
    "person.relationtype.avi": "Abuelo",
    "person.relationtype.avia": "Abuela",
    "person.relationtype.cangur": "Canguro",
    "person.relationtype.altres": "Relación desconocida",
    "parent.delete": "¿Seguro que quieres eliminar el familiar '{name}'?",
    "person.type.admin": "Administrador",
    "person.type.student": "alumno",
    "person.type.teacher": "Docente",
    "person.type.parent": "Padre/Madre",
    "person.man": "Hombre",
    "person.woman": "Mujer",
    "person.undefined": "Indefinido",
    "TEACHER": "Profesor/a",
    "PAS": "PAS",
    "PARENT": "Padre/Madre",
    "ADMIN": "Administrador",
    "user.rol":"Rol",
    "person.related.not_exist": "La persona no tiene familiares (padre/madre/tutor legal...) relacionados.",
    "person.related.children.not_exist": "La persona no tiene hijos relacionados.",
    "user.basicData" : "Datos de usuario",
    "person.academicrecord": "Expediente académico",
    "person.documents": "Documentos",
    "person.user.not_exist": "La persona no tiene usuario y por lo tanto no puede acceder a la intranet.",
    "enrolment": "Matrícula",
    "enrolment.info": "Datos de la matrícula",
    "enrolment.numCal": "Convocatoria",
    "enrolment.validateNote": "Nota convalidada",
    "enrolment.programs.select": "No está matriculado/a a ninguna materia",
    "enrolment.state.ACTIVE": "Matriculado",
    "enrolment.state.NO_ENROLMENT":" No Matriculado",
    "enrolment.state.CANCELED": "Baja",
    "person.isImportable": "Se actualiza del importador",
    "person.children": "Hijos",
    "warning.deleterole": "¿Seguro que quieres eliminar el rol \"{role}\"?",
    "addsubrole": "Añadir subrol",
    "editrole": "Editar rol",
    "roleusers": "Usuarios de este rol",
    "deleteuser.title": "Eliminar usuario",
    "deleteuser.body": "¿Seguro que quieres eliminar el usuario {name} ?",
    "adduser.title": "Busca el usuario que quieres añadir al rol {role}",
    "person.status": "Estado persona",
    "person.additionalData": "Datos adicionales",
    "person.addUserAlert": "Para añadir usuario haga clic en el botón ",
    "child.delete": "¿Seguro que quieres eliminar el hijo '{name}'?",
    "person.children.not_exist": "La persona no tiene hijos relacionados.",
    "sendUserPassReminder": "Enviar claves de acceso",
    "noPersonsInList": "El listado de usuarios está vacío.",
    "titleSendUserReminder": "Enviar recordatorio de usuario",
    "confirmSendUserReminder": "A continuación se enviará un correo electrónico recordatorio con el nombre de usuario y un enlace para recuperar la contraseña, a todos los usuarios del listado.<br /><br />¿Está seguro que quiere enviar los correos electrónicos recordatorio?",
    "userReminderSent": "No se ha enviado ningún correo electrónico.|Se ha enviado 1 correo electrónico.|Se han enviado {totalSent} correos electrónicos.",
    "personExtraField.filed.delete": "Está a punto de borrar el campo personalizado {fileName}. Si hay valores asociados a este campo en las fichas de las personas, los perderá. ",
}
