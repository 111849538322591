import { render, staticRenderFns } from "./CardSecurity.vue?vue&type=template&id=62a85b05&scoped=true"
import script from "./CardSecurity.vue?vue&type=script&lang=js"
export * from "./CardSecurity.vue?vue&type=script&lang=js"
import style0 from "./CardSecurity.vue?vue&type=style&index=0&id=62a85b05&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62a85b05",
  null
  
)

export default component.exports