import entityHelper from '@/api/entityHelper';
import axios from 'axios';

export default {
  getTutors(groupCourseStage, schemaYear) {
    let parameters ={};
    parameters.groupCourseStageId= groupCourseStage;
    if (schemaYear !== null) {
      parameters.changeYear= schemaYear
    }
    return axios.get('/tutor',{params:parameters}  );
  },
  getAvailableTutors(groupCourseStage, year) {
    let parameters = new FormData();
    if(year != undefined){
      parameters.append('year', year);
    }
    return axios.get('/tutor/?notInGroupCourseStage=' + groupCourseStage, parameters);
  },
  saveTutor(groupCourseStage, isTutor, personId, year) {
    let parameters = new FormData();
    parameters.append('groupCourseStageId', groupCourseStage);
    parameters.append('isTutor', isTutor);
    parameters.append('person-id', personId);
    if(year != undefined){
      parameters.append('year', year);
    }
    return axios.post('/tutor', parameters);
  },
  deleteTutor(id, year) {
    let parameters = new FormData();
    if(year != undefined){
      parameters.append('year', year);
    }
    return axios.delete('/tutor/' + id, parameters);
  },
  getTutorsByStageType(stageType, year){
    let parameters ={};
    if(stageType!==null && stageType !== undefined){
      parameters.stageType = stageType;
    }
    if(year != undefined){
      parameters.year = year;
    }
    return axios.get('/tutor',{params:parameters}  );
  },
  /**
   * Comprovació per saber si l'usuari és tutor de l'alumne o no
   * @param {number} studentId
   * @param {number} tutorId
   * @param {string} year Codi de l'any per treballar en una estructura d'any o l'altre
   */
  getIsTutorStudent(studentId, tutorId, year = null){
    let params = {
      studentId: studentId,
    };
    if(tutorId) {
      params.tutorId = tutorId;
    }
    if(year) {
      params.year = year;
    }
    return axios.get('/check-tutor', {params});
  },
}
