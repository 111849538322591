import menuApi from '../../api/core/menu'

const initialState = () => ({
    config: null,
    menu: null,
    force: true,
    collapsed: false,
});
export const menu = {
    state: initialState(),
    mutations: {
        setConfig(state, config) {
            state.config = config;
        },
        setMenu(state, menu) {
            state.menu = menu;
        },
        deleteMenus(state) {
            state.config = [];
            state.menu = [];
        },
        setForceMenu(state, force){
            //console.log("setForceMenu", force);
            state.force = force;
        },
        resetCenterInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
            //console.log("resetCenterInfo posem a false? ", state.force);
        },
        setCollapsed(state, value){
            state.collapsed = value;
        }
    },
    actions: {
        existInMenu({state}, route) {
            let exists = false;
            let i = 0;
            while (i < state.menu.length && !exists) {
                exists = state.menu[i].route === route;
                i++;
            }
            while (i < state.config.length && !exists) {
                exists = state.config[i].route === route;
                i++;
            }
            return exists;
        },
        async loadMenu(context, params){
            // console.log("store loadMenu force: ",context.state.force);
            if (context.state.menu !== null && context.state.menu !== "pending" && context.state.config !== null && context.state.config !== "pending" && context.state.force!=true){
                //console.log("retornem de l'store loadMenu");
                return context.state.menu;
            }
            else if ((context.state.menu !== "pending" && context.state.config !== "pending") || context.state.force==true){
                context.commit('setMenu', "pending");
                context.commit('setConfig', "pending");
                // console.log("fem petició loadMenu");
                await menuApi.loadMenu(context.state.force).then(response => {
                    if(response){
                        context.commit('setMenu', response.data['menu']);
                        context.commit('setConfig', response.data['config']);
                    }
                });
            }
        },
    },
    getters: {
        config: state => {
            return state.config;
        },
        menu: state => {
            return state.menu;
        },
        force: state =>{
            return state.force;
        },
        isCollapsed: state => {
            return state.collapsed;
        }
    }

}
