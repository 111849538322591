import authApi from '@/api/core/auth';
import axios from 'axios';
import router from '@/router';
import Users from "@/api/core/users";
import store from "./store";
import securityHelper from "../utils/securityHelper";

export const auth = {
  state: {
    access_token: null,
    auth_error: null,
    anonymous: null,
    cancelToken: axios.CancelToken.source(),
    logoutCancelToken: axios.CancelToken.source(),
    expireTime: null,
    isAlreadyFetchingAccessToken: false,
    reload: false,
    errorMessage: null,
    requestsWaiting: [],
    timeCancelToken: undefined
  },
  mutations: {
    setReload(state, value) {
      // console.log('setReload');
      state.reload = value;
    },
    alreadyFetchingAccessToken(state, param) {
      // console.log('alreadyFetchingAccessToken');
      state.isAlreadyFetchingAccessToken = param;
    },
    setAuthError(state,value){
      // console.log('setAuthError');
        state.auth_error = value;
    },
  },
  actions: {
      cleanSession(){
          localStorage.removeItem("refresh");
          localStorage.removeItem("anonymous");
          localStorage.removeItem("expireTime");
          localStorage.removeItem("language");
          sessionStorage.removeItem("token");
          localStorage.removeItem('isLoggedIn');
          localStorage.removeItem('clientCode');
          localStorage.removeItem('isTokapp');
          securityHelper.deleteCookie('ieduca.com');
          ability.update([]);
      },
    logout({ state, dispatch }) {
        state.logoutCancelToken = axios.CancelToken.source();
        let logoutCancelToken = state.logoutCancelToken.token;
        axios.get('/logout', { cancelToken: logoutCancelToken })
            .then(r => {
                console.log("Resposta logout i netegem sessió ",r);
                dispatch("cleanSession");
            })
        .catch(e => {
            console.log("Error logout:  ",e)
            dispatch("cleanSession");
        });
    },
    async anonimusToken({state}, params) {
      // console.log("anonimusToken");
      await authApi.requestAnonimusToken().then(response => {
        // console.log("anonimusToken response");
        state.auth_error = false;
        state.access_token = response.data.access_token;
        sessionStorage.setItem('token', response.data.access_token);
        //console.log("setExpireTime 2", response.data, response.data.expires_in);
        localStorage.setItem('expireTime', response.data.expires_in);
        localStorage.anonymous = state.anonymous = true;
        // Add an axios filter to set a header with the token
        // console.log("anonimusToken response 1");
        axios.interceptors.request.use(function (config) {
          config.headers['Authorization'] = 'Bearer ' + state.access_token;
          return config;
        })
      }, error => {
        state.auth_error = true;
      });
      // console.log("anonimusToken end async");
    },
    async refreshToken({state}, params) {
      // console.log('refeshToken Store, isAlreadyFetchingAccessToken:' + state.isAlreadyFetchingAccessToken);
      if (state.isAlreadyFetchingAccessToken) return;
      state.isAlreadyFetchingAccessToken = true;
      // console.log("refreshToken 1");
      await authApi.refreshToken().then(response => {
        // console.log("refreshToken response ");
        if (axios.isCancel(response)) {
          return;
        }
        state.auth_error = false;
        // console.log("refreshToken 2");
        sessionStorage.setItem('token', response.data.access_token);
        // console.log("refreshToken 3");
        localStorage.setItem('refresh', response.data.refresh_token);
        // console.log("refreshToken 4",axios);
        // Add an axios filter to set a header with the token
        if(axios===undefined) console.log('axios undefinded !!!');
        let cancelToken = axios.CancelToken.source();
        // console.log("refreshToken 4-1");
        state.cancelToken = cancelToken;
        // console.log("refreshToken 5");
        var date = new Date();
        // console.log("refreshToken 6");
        localStorage.expireTime = date.getTime() + (response.data.expires_in);// - 180000;
        console.log("setExpireTime 4", date.getTime(), response.data, response.data.expires_in);
        // console.log("refreshToken 7");
        axios.interceptors.request.use(function (config) {
          // console.log("refreshToken response 2");
          config.headers['Authorization'] = 'Bearer ' + sessionStorage.token;
          config.cancelToken = state.cancelToken.token;
          return config;
        });
        // console.log("refreshToken 8");
        state.isAlreadyFetchingAccessToken = false;
        // console.log("refreshToken 9");
        if (state.reload) {
          location.reload();
        }
        // console.log("refreshToken 10");
      }, error => {
        // console.log("refreshToken error");
        localStorage.removeItem("refresh");
        state.auth_error = true;
        state.isAlreadyFetchingAccessToken = false;
      });
    },
    generateCancelToken({state}) {
      // console.log('generant un  generateCancelToken');
      // console.log(' generateCancelToken mirem state:',state);
      let date = new Date();
      state.timeCancelToken = date.getTime();
      // console.log('control anti bucle',date.getTime(),state);
      state.cancelToken = axios.CancelToken.source();
      var token = state.access_token = sessionStorage.token;
      axios.interceptors.request.use(function (config) {
        config.headers['Authorization'] = 'Bearer ' + token;
        if(config.cancelToken == null || config.cancelToken === "null" || typeof config.cancelToken === "undefined" || config.cancelToken === "undefined"){
          config.cancelToken = state.cancelToken.token;
        }
        return config;
      });
    },
    setToken(state, response) {
      sessionStorage.token = response.data.access_token;
      state.auth_error = false;
      state.access_token = response.data.access_token;
      localStorage.anonymous = state.anonymous = false;
      localStorage.refresh = response.data.refresh_token;
      let date = new Date();
      localStorage.expireTime = date.getTime() + (response.data.expires_in * 1000);
      console.log("setExpireTime 3", date.getTime(), response.data, response.data.expires_in);
    }
  },
  getters: {
    needReload: state => {
      return state.reload;
    },
    isRefreshing: state => {
      return state.isAlreadyFetchingAccessToken;
    },
      cancelToken: state => {
        return state.cancelToken;
      },
     cancelTokenTime: state=>{
      return state.timeCancelToken;
     }
  }

}
