export const message = {
    state: {
        messageFolder: null,
    },
    mutations: {
        setMessageFolder(state, messageFolder){
            state.messageFolder = messageFolder;
        },
    },
    actions: {
    },
    getters: {
        getMessageFolder: state => {
            return state.messageFolder;
        },
    }
};