import axios from 'axios';
import entityHelper from '@/api/entityHelper'

export default {
    getModules() {
        return axios.get('/modules');
    },
    activeModule(moduleId) {
        let params = {
            moduleId: moduleId
        };
        return axios.post("/modules/activate-module", {params});
    },
    disactiveModule(moduleId) {
        let params = {
            moduleId: moduleId
        };
        return axios.post("/modules/disactivate-module", {params});
    },
    getModuleParameters(moduleId){
        return axios.get("/modules/parameters?moduleId=" + moduleId);
    },
    saveParametersModule(moduleName, parameters) {
        // Parameters es un objecte i una de les claus que ha de tenir es "moduleName"
        return axios.post("/modules/save", parameters);
    }
}
