import academicStructureApi from '@/api/core/academicStructure'
import stageApi from '@/api/core/stage'
import courseStageApi from '@/api/core/courseStage';
import Vue from 'vue';

const initialState = () => ({
    groupsCoursesStages: {},
    courseStageId: null,
    stageId: null,
    groupCourseStageId: null,
    stages: null,
    studentsByGroupCourseStage: {},
    studentsGridData: {},
    tutorsByGroupCourseStage: {},
    stageCourseStages:[],
    commerces: [],
    infoAcademy: [],
    infoBasic: [],
    courseStagesByStage: {},
});

export const academicStructure = {
    state: initialState(),
    mutations: {
        setGroupsCoursesStages(state,groupsCoursesStages){
            state.groupsCoursesStages = groupsCoursesStages;
        },
        setCourseStageId(state,courseStageId){
            state.courseStageId = courseStageId;
        },
        setStageId(state,stageId){
            state.stageId = stageId;
        },
        setGroupCourseStageId(state,groupCourseStageId){

            state.groupCourseStageId = groupCourseStageId;
        },
        setStages(state, stages){
            state.stages = stages;
        },
        resetCenterInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        setStudentsByGroupCourseStage(state, params){
            let groupCourseStageId = params.groupCourseStageId;
            let students = params.students;
            state.studentsByGroupCourseStage[groupCourseStageId] = students;
        },
        setStudentsGridData(state, params){
            let groupCourseStageId = params.groupCourseStageId;
            let data = params.data;
            let programs = params.programs;
            let otherStudents = params.otherStudents;
            state.studentsGridData[groupCourseStageId] = {data: data, programs: programs, otherStudents: otherStudents};
        },
        setTutorsByGroupCourseStage(state, params){
            let groupCourseStageId = params.groupCourseStageId;
            let tutors = params.tutors;
            let persons = params.persons;
            state.tutorsByGroupCourseStage[groupCourseStageId] = {tutors: tutors, persons: persons};
        },
        setStageCourseStages(state, stageCourseStages){
            state.stageCourseStages = stageCourseStages;
        },
        setCommerces(state, commerces){
            state.commerces = commerces;
        },
        setCourseStagesByStage(state, params){
            let stageId = params.stageId;
            let courseStages = params.courseStages;
            Vue.set(state.courseStagesByStage, stageId, courseStages);
        }
    },
    getters: {
        academicStructure: state => {
            return state;
        },
        stages: state => {
            return state.stages;
        },
        studentsByGroupCourseStage: state => {
            return state.studentsByGroupCourseStage;
        },
        studentsGridData: state => {
            return state.studentsGridData;
        },
        tutorsByGroupCourseStage: state => {
            return state.tutorsByGroupCourseStage;
        },
        stageCourseStages: state => {
            return state.stageCourseStages;
        },
        getCommerces: state => {
            return state.commerces;
        },
        getCourseStagesByStage: state => {
            return state.courseStagesByStage;
        },
        getGroupsCoursesStagesStore: state => {
            return state.groupsCoursesStages;
        }
    },
    actions: {
        getCoursesStage(context,params){
            let stageId = params.stageId ;
            let force = params.force ;
            let yearCode = params.yearCode;

            if(!stageId){
                stageId = context.state.stageId;
            }else{
                context.commit('setStageId',stageId);
            }
            if(!force && context.state.courseStagesByStage[stageId] !== "pending" && context.state.courseStagesByStage[stageId] && context.state.courseStagesByStage[stageId].length > 0){
                return context.state.courseStagesByStage;
            } else if(context.state.courseStagesByStage[stageId] !== "pending"){
                context.commit("setCourseStagesByStage", {courseStages: "pending", stageId: stageId});
                academicStructureApi.getCoursesStage(stageId, yearCode).then(response => {
                    if (response) {
                        context.commit("setCourseStagesByStage", {courseStages: response.data, stageId: stageId});
                    } else {
                        context.commit("setCourseStagesByStage", {courseStages: [], stageId: stageId});
                    }
                    return context.state.stageCourseStages;
                }).catch(e => {
                    context.commit("setCourseStagesByStage", {courseStages: [], stageId: stageId});
                });
            }
        },
        getStageCourseStages(context, params){
            let force = false;
            let viewAll = true;
            let personId = null;
            if(typeof params != "undefined"){
                if('force' in params){ force = params.force; }
                if('viewAll' in params){ viewAll = params.viewAll; }
                if('personId' in params){ personId = params.personId; }
            }
            if(!force && context.state.stageCourseStages!=="pending" && context.state.stageCourseStages && context.state.stageCourseStages.length > 0){
                return context.state.stageCourseStages;
            } else if(context.state.stageCourseStages!=="pending"){
                context.commit("setStageCourseStages", "pending");
                courseStageApi.getStageCourseStages(viewAll, personId).then(response => {
                    if (response) {
                        context.commit("setStageCourseStages", response.data.courseStages);
                    } else {
                        context.commit("setStageCourseStages", []);
                    }
                    return context.state.stageCourseStages;
                }).catch(e => {
                    context.commit("setStageCourseStages", []);
                });
            }
        },
        getProgramsCourseStage(context,courseStageId, yearCode){
             if(courseStageId == undefined){
                courseStageId = state.courseStageId;
            }else{
              context.commit('setCourseStageId',courseStageId);
            }
            if(courseStageId != null && yearCode != null){
               return academicStructureApi.getProgramsCourseStage(courseStageId, yearCode);
            }
        },
        getStudentsByGroupCourseStage(context, groupCourseStageId){
            return context.studentsByGroupCourseStage[groupCourseStageId];;
        },
        getCommerces(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.commerces!=="pending" && context.state.commerces && context.state.commerces.length > 0){
                return context.state.commerces;
            } else if(context.state.commerces!=="pending"){
                context.commit("setCommerces", "pending");
                academicStructureApi.getCommerces().then((response) => {
                    if (response) {
                        context.commit("setCommerces", response.data);
                    } else {
                        context.commit("setCommerces", []);
                    }
                    return context.state.commerces;
                }).catch(e => {
                    context.commit("setCommerces", []);
                });
            }
        },
        getStages(context,params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.stages!=="pending" && context.state.stages && context.state.stages.length > 0){
                return context.state.stages;
            } else if(context.state.stages!=="pending"){
                context.commit("setStages", "pending");
                let filters = [];
                stageApi.findStages(filters, 1, 99999, 'id', 'asc').then((response) => {
                    if (response) {
                        context.commit("setStages", response.data.data);
                    } else {
                        context.commit("setStages", []);
                    }
                    return context.state.stages;
                }).catch(e => {
                    context.commit("setStages", []);
                });
            }
        },
        getGroupsCoursesStages(context, params){
            academicStructureApi.getDropdownStructure().then(response => {
                if(response){
                    let mainData = response.data.all;
                    context.commit('setGroupsCoursesStages', mainData);
                }
            });
        }
     }
}

