import PersonApi from "@/api/core/person";
import moment from 'moment';

const initialState = () => ({
    personsFoundFilters: null,
    currentPersonRoles: [],
    allPersonRoles: [],
    children: [],
});

export const persons = {
    state: initialState(),
    mutations: {
        resetUserInfo(state) {
            const newState = initialState();
            Object.keys(newState).forEach(key => {
                state[key] = newState[key]
            });
        },
        personsFoundFilters(state, persons) {
            state.personsFoundFilters = persons;
        },
        resetCenterInfo(state) {
            state.personsFoundFilters = null;
            state.currentPersonRoles = [];
        },
        setCurrentPersonRoles(state, personRoles){
            state.currentPersonRoles = personRoles;
        },
        setChildren(state, children){
            state.children = children;
        }
    },
    getters: {
        getPersonsFoundFilters: state => {
            return state.personsFoundFilters;
        },
        getCurrentPersonRoles: state => {
            return state.currentPersonRoles;
        },
        getCurrentPersonIsStudent: state => {
            let returnRole = null;
            let i = 0;
            while(i <= state.currentPersonRoles.length-1 && !returnRole){
                if (state.currentPersonRoles[i].keyName === "groupRole.STUDENT"){
                    returnRole = state.currentPersonRoles[i];
                }
                i++;
            }
            return !!returnRole;
        },
        getCurrentPersonIsOver18: state => {
            if(state.currentPerson){
                let years = moment().diff(state.currentPerson.birthday, 'years');
                if(years >= 18){
                    return true;
                }
                else{
                    return false;
                }
            }
            else{
                return false;
            }
        },
        getChildren: state => {
            return state.children;
        }
    },
    actions: {
        async currentPersonRoles(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.currentPersonRoles!="pending" && context.state.currentPersonRoles && context.state.currentPersonRoles.length > 0){
                return context.state.currentPersonRoles;
            } else if(context.state.currentPersonRoles!="pending"){
                context.commit("setCurrentPersonRoles", "pending");
                await PersonApi.getPersonRoles().then((response) => {
                    if (response) {
                        context.commit("setCurrentPersonRoles", response.data);
                    } else {
                        context.commit("setCurrentPersonRoles", []);
                    }
                    return context.state.currentPersonRoles;
                }).catch(e => {
                    context.commit("setCurrentPersonRoles", []);
                });
            }
        },
        async getChildren(context, params){
            let force = false;
            if(typeof params != "undefined" && 'force' in params){   force = params.force;  }
            if(!force && context.state.children !== "pending" && context.state.children && context.state.children.length > 0){
                return context.state.children;
            } else if(context.state.children !== "pending" && params.personId){
                context.commit("setChildren", "pending");
                await PersonApi.getChildren(params.personId).then((response) => {
                    if (response) {
                        context.commit("setChildren", response.data.data);
                    } else {
                        context.commit("setChildren", []);
                    }
                    return context.state.children;
                }).catch(e => {
                    context.commit("setChildren", []);
                });
            }
        },
    }
}

